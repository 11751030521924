import type { Options } from '../types';
import type { SendEmailPayload } from './types';

const actions = {
  FETCH_EMAILS_REQUEST: 'FETCH_EMAILS_REQUEST',
  FETCH_EMAILS_SUCCESS: 'FETCH_EMAILS_SUCCESS',
  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  DELETE_EMAIL_REQUEST: 'DELETE_EMAIL_REQUEST',
  FORCE_EMAIL_REQUEST: 'FORCE_EMAIL_REQUEST',
  EMAIL_SUCCESS: 'EMAIL_SUCCESS',

  fetchEmails: (payload: Options, setLoading?: (pay: any) => void) => ({
    type: actions.FETCH_EMAILS_REQUEST,
    payload,
    setLoading,
  }),
  sendEmail: (
    payload: SendEmailPayload,
    partnerId: number,
    callback?: () => void
  ) => ({
    type: actions.SEND_EMAIL_REQUEST,
    payload,
    partnerId,
    callback,
  }),
  deleteEmail: (id: number, partnerId: number, callback?: () => void) => ({
    type: actions.DELETE_EMAIL_REQUEST,
    id,
    partnerId,
    callback,
  }),
  forceSendEmail: (id: number, partnerId: number, callback?: () => void) => ({
    type: actions.FORCE_EMAIL_REQUEST,
    id,
    partnerId,
    callback,
  }),
};

export default actions;
