const actions = {
  FETCH_GOALS_REQUEST: 'FETCH_GOALS_REQUEST',
  FETCH_GOALS_SUCCESS: 'FETCH_GOALS_SUCCESS',
  POST_GOALS_REQUEST: 'POST_GOALS_REQUEST',
  POST_GOALS_SUCCESS: 'POST_GOALS_SUCCESS',
  UPDATE_GOALS_REQUEST: 'UPDATE_GOALS_REQUEST',
  UPDATE_GOALS_SUCCESS: 'UPDATE_GOALS_SUCCESS',

  fetchGoals: (payload: any) => ({
    type: actions.FETCH_GOALS_REQUEST,
    payload,
  }),
  insertGoals: (goals: any, callback?: () => void) => ({
    type: actions.POST_GOALS_REQUEST,
    payload: goals,
    callback,
  }),
  updateGoals: (id: number, goals: any, callback?: () => void) => ({
    type: actions.UPDATE_GOALS_REQUEST,
    id: id,
    payload: goals,
    callback,
  }),
};

export default actions;
