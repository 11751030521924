import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../../helpers/api';
import appActions from '../../app/actions';
import type { GeneralSuccess } from '../../types';
import actions from './actions';
import type {
  actionDeleteNotificationRequest,
  actionEndNotificationRequest,
  actionFetchNotificationsRequest,
  actionInsertNotificationRequest,
  actionUpdateNotificationRequest,
} from './types';

/*
agora fiquei puto, virei o leo, vo comenta
mano porq motivos essa merda de yield select fica quebrando o trycatch e n me mostra o erro caralho
duas horas aqui revirando codigo pra ver que faltava um 's' aqui nessa merda de state.Notiticfaf=-asjiufwsdgjkfisdjifgdsuyg
*/
export const NotificationList = (state: any) => state.Notifications.notifications;

export function* fetchNotificationsRequest() {
  yield takeEvery(
    actions.FETCH_NOTIFICATIONS_REQUEST,
    function* ({ payload, setLoading }: actionFetchNotificationsRequest) {
      try {
        const notificationList: any = yield select(NotificationList);

        if (setLoading && typeof setLoading === 'function') {
          setLoading(true);
        } else {
          yield put({ type: appActions.IS_LOADING, payload: true });
        }
        const result: any = yield call(api.fetchNotifications, payload);
        if (setLoading && typeof setLoading === 'function') {
          setLoading(false);
        } else {
          yield put({ type: appActions.IS_LOADING, payload: false });
        }
        if (
          (result.meta && result.meta.current_page === 1) ||
          !notificationList.data
        ) {
          yield put({
            type: actions.FETCH_NOTIFICATIONS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_NOTIFICATIONS_SUCCESS,
            payload: {
              ...notificationList,
              data: [...notificationList.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertNotificationRequest() {
  yield takeEvery(
    actions.INSERT_NOTIFICATION_REQUEST,
    function* ({ payload, callback, filters }: actionInsertNotificationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.insertNotification, payload);
        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.NOTIFICATION_SUCCESS,
          payload: {
            title: 'Notificação criada',
            message: 'Notificação criada com sucesso',
            filters,
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* updateNotificationRequest() {
  yield takeEvery(
    actions.UPDATE_NOTIFICATION_REQUEST,
    function* ({ id, payload, callback, filters }: actionUpdateNotificationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.updateNotification, id, payload);
        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.NOTIFICATION_SUCCESS,
          payload: {
            title: 'Notificação editada',
            message: 'Notificação editada com sucesso',
            filters,
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* deleteNotificationRequest() {
  yield takeEvery(
    actions.DELETE_NOTIFICATION_REQUEST,
    function* ({ id, callback, filters }: actionDeleteNotificationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deleteNotification, id);
        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.NOTIFICATION_SUCCESS,
          payload: {
            title: 'Novidade excluída',
            message: 'Novidade excluída com sucesso',
            filters,
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* endNotificationRequest() {
  yield takeEvery(
    actions.END_NOTIFICATION_REQUEST,
    function* ({ id, callback, filters }: actionEndNotificationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.endNotification, id);
        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.NOTIFICATION_SUCCESS,
          payload: {
            title: 'Novidade finalizada',
            message: 'Novidade finalizada com sucesso',
            filters,
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* notificationSuccess() {
  yield takeEvery(
    actions.NOTIFICATION_SUCCESS,
    function* ({ payload }: GeneralSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          title: payload.title,
          message: payload.message,
        },
      });
      yield put({
        type: actions.FETCH_NOTIFICATIONS_REQUEST,
        payload: { ...payload.filters, page: 1 },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchNotificationsRequest),
    spawn(insertNotificationRequest),
    spawn(updateNotificationRequest),
    spawn(deleteNotificationRequest),
    spawn(endNotificationRequest),
    fork(notificationSuccess),
  ]);
}
