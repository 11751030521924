const actions = {
  FETCH_GRAPHICS_REQUEST: 'FETCH_GRAPHICS_REQUEST',
  FETCH_GRAPHICS_SUCCESS: 'FETCH_GRAPHICS_SUCCESS',

  fetchGraphics: (payload: { data_inicial: string; data_final: string }) => ({
    type: actions.FETCH_GRAPHICS_REQUEST,
    payload,
  }),
};

export default actions;
