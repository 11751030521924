import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../../helpers/api';
import { estruturaListaPlanos } from 'shared/estruturaDeDados/estruturaListaPlanos';
import appActions from '../../app/actions';
import type { GeneralSuccess } from '../../types';
import plansActions from './actions';
import type {
  actionDeletePlanRequest,
  actionFetchMeuPlanoRequest,
  actionFetchPlanosEGruposRequest,
  actionFetchPlanRequest,
  actionFetchPlansGroupsRequest,
  actionFetchPlansListRequest,
  actionInsertPlanRequest,
  actionPatchAdicionarRecursoPlanoRequest,
  actionPatchAdicionarRecursoPlanoSuccess,
  actionPatchRemoverRecursoPlanoRequest,
  actionPatchRemoverRecursoPlanoSuccess,
  actionSetPlanSituationRequest,
  actionUpdatePlanRequest,
} from './types';

export const PlansList = (state: any) => state.Plans.plansList;
export const PlansGroupsList = (state: any) => state.Plans.plansGroupsList;

export function* fetchPlansGroupsRequest() {
  yield takeEvery(
    plansActions.FETCH_PLANSGROUPS_REQUEST,
    function* ({ payload, setLoading }: actionFetchPlansGroupsRequest) {
      try {
        const plansGroupsList: any = yield select(PlansGroupsList);

        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchPlansGroups, payload);
        if (typeof setLoading === 'function') {
          setLoading(false);
        }

        yield put({ type: appActions.IS_LOADING, payload: false });
        if (
          (result.meta && result.meta.current_page === 1) ||
          !plansGroupsList.data
        ) {
          yield put({
            type: plansActions.FETCH_PLANSGROUPS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: plansActions.FETCH_PLANSGROUPS_SUCCESS,
            payload: {
              ...plansGroupsList,
              data: [...plansGroupsList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchPlansListRequest() {
  yield takeEvery(
    plansActions.FETCH_PLANS_LIST_REQUEST,
    function* ({ payload, setLoading }: actionFetchPlansListRequest) {
      try {
        const plansList: any = yield select(PlansList);

        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchActivePlans, payload);
        if (typeof setLoading === 'function') {
          setLoading(false);
        }

        yield put({ type: appActions.IS_LOADING, payload: false });
        if ((result.meta && result.meta.current_page === 1) || !plansList.data) {
          yield put({
            type: plansActions.FETCH_PLANS_LIST_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: plansActions.FETCH_PLANS_LIST_SUCCESS,
            payload: {
              ...plansList,
              data: [...plansList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchHistoricoPlanosRequest() {
  yield takeEvery(
    plansActions.FETCH_HISTORICO_PLANOS_REQUEST,
    function* ({ id, payload }: any) {
      try {
        const result: Promise<any> = yield call(
          api.fetchHistoricoPlanos,
          id,
          payload
        );

        yield put({
          type: plansActions.FETCH_HISTORICO_PLANOS_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* patchAdicionarRecursoPlanoRequest() {
  yield takeEvery(
    plansActions.PATCH_ADICIONAR_RECURSO_PLANO_REQUEST,
    function* ({
      codigoClienteParceiro,
      idRecurso,
      valorRecurso,
    }: actionPatchAdicionarRecursoPlanoRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(
          api.patchAdicionarRecursoPlano,
          codigoClienteParceiro,
          idRecurso,
          valorRecurso
        );

        yield put(
          plansActions.patchAdicionarRecursoPlanoSuccess(codigoClienteParceiro)
        );
        yield put({ type: appActions.IS_LOADING, payload: false });

        yield put(
          appActions.toggleSnackBar({
            activated: true,
            title: 'Recurso adicionado com sucesso!',
            variant: 'success',
          })
        );
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });

        yield put(
          appActions.toggleSnackBar({
            activated: true,
            title: 'Houve algum erro ao adicionar um recurso!',
            variant: 'danger',
          })
        );
      }
    }
  );
}

export function* patchAdicionarRecursoPlanoSuccess() {
  yield takeEvery(
    plansActions.PATCH_ADICIONAR_RECURSO_PLANO_SUCCESS,
    function* ({ codigoClienteParceiro }: actionPatchAdicionarRecursoPlanoSuccess) {
      try {
        yield put(plansActions.fetchMeuPlanoRequest(codigoClienteParceiro));
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* patchRemoverRecursoPlanoRequest() {
  yield takeEvery(
    plansActions.PATCH_REMOVER_RECURSO_PLANO_REQUEST,
    function* ({
      codigoClienteParceiro,
      idRecurso,
      imediatamente,
    }: actionPatchRemoverRecursoPlanoRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(
          api.patchRemoverRecursoPlano,
          codigoClienteParceiro,
          idRecurso,
          imediatamente
        );

        yield put(
          plansActions.patchRemoverRecursoPlanoSuccess(codigoClienteParceiro)
        );
        yield put({ type: appActions.IS_LOADING, payload: false });

        yield put(
          appActions.toggleSnackBar({
            activated: true,
            title: 'Recurso removido com sucesso!',
            variant: 'success',
          })
        );
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put(
          appActions.toggleSnackBar({
            activated: true,
            title: 'Houve algum erro ao remover um recurso!',
            variant: 'danger',
          })
        );
      }
    }
  );
}

export function* patchRemoverRecursoPlanoSuccess() {
  yield takeEvery(
    plansActions.PATCH_REMOVER_RECURSO_PLANO_SUCCESS,
    function* ({ codigoClienteParceiro }: actionPatchRemoverRecursoPlanoSuccess) {
      try {
        yield put(plansActions.fetchMeuPlanoRequest(codigoClienteParceiro));
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchMeuPlanoRequest() {
  yield takeEvery(
    plansActions.FETCH_MEU_PLANO_REQUEST,
    function* ({
      codigoClienteParceiro,
      setMostrar = null,
    }: actionFetchMeuPlanoRequest) {
      try {
        const result: any = yield call(api.fetchMeuPlano, codigoClienteParceiro);

        yield put({
          type: plansActions.FETCH_MEU_PLANO_SUCCESS,
          payload: result,
        });

        if (typeof setMostrar === 'function') {
          setMostrar(true);
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchPlanosEGruposRequest() {
  yield takeEvery(
    plansActions.FETCH_PLANOS_E_GRUPOS_REQUEST,
    function* ({ payload }: actionFetchPlanosEGruposRequest) {
      try {
        const planosEstrutura = { planos: {} };

        const result: any = yield call(api.fetchPlanosEGrupos, payload);

        planosEstrutura.planos = yield estruturaListaPlanos(result.data.planos);

        yield put({
          type: plansActions.FETCH_PLANOS_E_GRUPOS_SUCCESS,
          payload: planosEstrutura,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchPlanRequest() {
  yield takeEvery(
    plansActions.FETCH_PLAN_REQUEST,
    function* ({ payload }: actionFetchPlanRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.fetchPlan, payload);
        yield put({ type: plansActions.FETCH_PLAN_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertPlanRequest() {
  yield takeEvery(
    plansActions.INSERT_PLAN_REQUEST,
    function* ({ payload, filters, callback }: actionInsertPlanRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.insertPlan, payload);
        yield put({
          type: plansActions.PLAN_SUCCESS,
          payload: {
            title: 'Plano criado',
            message: 'Seu plano foi criado com sucesso.',
            filters: filters,
          },
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* updatePlanRequest() {
  yield takeEvery(
    plansActions.UPDATE_PLAN_REQUEST,
    function* ({ id, payload, filters, callback }: actionUpdatePlanRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.updatePlan, id, payload, callback);
        yield put({
          type: plansActions.PLAN_SUCCESS,
          payload: {
            title: 'Plano editado',
            message: 'Seu plano foi editado com sucesso.',
            filters: filters,
          },
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* deletePlanRequest() {
  yield takeEvery(
    plansActions.DELETE_PLAN_REQUEST,
    function* ({ payload, filters, callback }: actionDeletePlanRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.deletePlan, payload);
        yield put({
          type: plansActions.PLAN_SUCCESS,
          payload: {
            title: 'Plano excluído',
            message: 'Seu plano foi excluído com sucesso.',
            filters: filters,
          },
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* setPlanSituationRequest() {
  yield takeEvery(
    plansActions.SET_PLAN_SITUATION_REQUEST,
    function* ({ id, situation, filters, callback }: actionSetPlanSituationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.setPlanSituation, id, situation);

        yield put({
          type: plansActions.PLAN_SUCCESS,
          payload: {
            title: `Plano ${situation ? 'ativado' : 'inativado'}`,
            message: `Seu plano foi ${
              situation ? 'ativado' : 'inativado'
            } com sucesso.`,
            filters: filters,
          },
        });

        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* planSuccess() {
  yield takeEvery(
    plansActions.PLAN_SUCCESS,
    function* ({ payload }: GeneralSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          title: payload.title,
          message: payload.message,
        },
      });
      yield put({
        type: plansActions.FETCH_PLANSGROUPS_REQUEST,
        payload: { page: 1, ...payload.filters },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(patchAdicionarRecursoPlanoRequest),
    spawn(patchRemoverRecursoPlanoRequest),
    spawn(fetchHistoricoPlanosRequest),
    spawn(fetchMeuPlanoRequest),
    spawn(fetchPlanosEGruposRequest),
    spawn(fetchPlansGroupsRequest),
    spawn(fetchPlansListRequest),
    spawn(fetchPlanRequest),
    spawn(insertPlanRequest),
    spawn(updatePlanRequest),
    spawn(deletePlanRequest),
    spawn(setPlanSituationRequest),
    fork(planSuccess),
    fork(patchRemoverRecursoPlanoSuccess),
    fork(patchAdicionarRecursoPlanoSuccess),
  ]);
}
