import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../../helpers/api';
import appActions from '../../app/actions';
import type { GeneralSuccess } from '../../types';
import actions from './actions';
import type {
  actionAllowUserAccessRequest,
  actionBlockUserAccessRequest,
  actionFetchUsersAccessRequest,
} from './types';

export const UsersAccessList = (state: any) => state.UsersAccess.usersAccess;

export function* fetchUsersAccessRequest() {
  yield takeEvery(
    actions.FETCH_USERSACCESS_REQUEST,
    function* ({ payload, setLoading }: actionFetchUsersAccessRequest) {
      try {
        const usersAccessList: any = yield select(UsersAccessList);

        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchUsersAccess, payload);
        if (typeof setLoading === 'function') {
          setLoading(false);
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
        if (
          usersAccessList.data.usuarios_com_permissao.length < 1 &&
          usersAccessList.data.usuarios_sem_permissao.length < 1
        ) {
          yield put({
            type: actions.FETCH_USERSACCESS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_USERSACCESS_SUCCESS,
            payload: {
              ...usersAccessList,
              data: { ...usersAccessList.data, ...result.data },
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* blockUserAccessRequest() {
  yield takeEvery(
    actions.BLOCK_USERACCESS_REQUEST,
    function* ({ id, callback }: actionBlockUserAccessRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.blockUserAccess, id);
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.USERACCESS_SUCCESS,
          payload: {
            title: 'Acesso bloqueado',
            message: 'O acesso do usuário foi bloqueado com sucesso',
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}
export function* allowUserAccessRequest() {
  yield takeEvery(
    actions.ALLOW_USERACCESS_REQUEST,
    function* ({ id, callback }: actionAllowUserAccessRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.allowUserAccess, id);
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.USERACCESS_SUCCESS,
          payload: {
            title: 'Acesso permitido',
            message: 'O acesso do usuário foi permitido com sucesso',
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* userAccessSuccess() {
  yield takeEvery(
    actions.USERACCESS_SUCCESS,
    function* ({ payload }: GeneralSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          title: payload.title,
          message: payload.message,
        },
      });
      yield put({
        type: actions.FETCH_USERSACCESS_REQUEST,
        payload: { page: 1 },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchUsersAccessRequest),
    spawn(blockUserAccessRequest),
    spawn(allowUserAccessRequest),
    fork(userAccessSuccess),
  ]);
}
