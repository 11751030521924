const actions = {
  GET_CITIES_REQUEST: 'GET_CITIES_REQUEST',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',

  getCities: (state: any) => ({
    type: actions.GET_CITIES_REQUEST,
    payload: state,
  }),
};
export default actions;
