import { defaultMeta } from '../../constants/defaultMeta';
import actions from './actions';
import type { DashboardStateProps } from './types';

const initialState: DashboardStateProps = {
  dashboard: {},
  errorsList: {
    data: [],
    extra: {},
    meta: defaultMeta,
  },
};

export default function gapsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload ? action.payload : initialState.dashboard,
      };
    case actions.FETCH_GAPS_SUCCESS:
      return {
        ...state,
        //gaps: action.payload ? action.payload : initialState.gaps,
      };
    case actions.FETCH_ERRORS_LIST_SUCCESS:
      return {
        ...state,
        errorsList: {
          data: action.payload ? action.payload.data : initialState.errorsList.data,
          extra: action.payload
            ? action.payload.extra
            : initialState.errorsList.extra,
          meta: action.payload ? action.payload.meta : initialState.errorsList.meta,
        },
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
