import type { Options } from '../../types';
import type { NotificationsPayload, PostNotificacaoBody } from './types';

const actions = {
  FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  INSERT_NOTIFICATION_REQUEST: 'INSERT_NOTIFICATION_REQUEST',
  UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
  DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
  END_NOTIFICATION_REQUEST: 'END_NOTIFICATION_REQUEST',
  NOTIFICATION_SUCCESS: 'NEWS_SUCCESS',

  fetchNotifications: (
    payload: Options,
    setLoading?: (loading: boolean) => void
  ) => ({
    type: actions.FETCH_NOTIFICATIONS_REQUEST,
    payload,
    setLoading,
  }),
  insertNotification: (
    payload: PostNotificacaoBody,
    callback?: () => void,
    filters?: Options
  ) => ({
    type: actions.INSERT_NOTIFICATION_REQUEST,
    payload,
    callback,
    filters,
  }),
  updateNotification: (
    id: number,
    payload: NotificationsPayload | any,
    callback?: () => void,
    filters?: Options
  ) => ({
    type: actions.UPDATE_NOTIFICATION_REQUEST,
    id,
    payload,
    callback,
    filters,
  }),
  deleteNotification: (id: number, callback?: () => void, filters?: Options) => ({
    type: actions.DELETE_NOTIFICATION_REQUEST,
    id,
    callback,
    filters,
  }),
  endNotification: (id: number, callback?: () => void, filters?: Options) => ({
    type: actions.END_NOTIFICATION_REQUEST,
    id,
    callback,
    filters,
  }),
};

export default actions;
