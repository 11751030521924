import Router from 'next/router';
import React from 'react';
import { all, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { removeCookie } from '../../helpers/session';
import appActions from './actions';
import type { actionHandleErrors, actionHandleSuccess } from './types';

export const getCookieName = (state: any) => state.Auth.cookieName;

export function* handleErrors() {
  yield takeEvery(
    appActions.HANDLE_ERRORS,
    function* ({ payload }: actionHandleErrors) {
      if (payload) {
        if (payload.error || payload.erro) {
          yield put({ type: appActions.IS_LOADING, payload: null });
          yield put({
            type: appActions.TOGGLE_SNACKBAR,
            payload: {
              activated: true,
              message: payload.error || payload.erro?.mensagem,
              variant: 'danger',
              time: payload.time ? payload.time : 3000,
            },
          });
        } else if (payload.errors) {
          yield put({ type: appActions.IS_LOADING, payload: null });
          const { errors } = payload;
          if (Array.isArray(errors)) {
            const count = errors.length;
            if (!errors.type) {
              //if (count === 1) {
              yield put({
                type: appActions.TOGGLE_SNACKBAR,
                payload: {
                  activated: true,
                  message: errors[0],
                  variant: 'danger',
                  time: payload.time ? payload.time : 3000,
                },
              });
              //}
              // else if (count > 1) { // modal que renderizava isso quebrada
              //   yield put({
              //     type: appActions.TOGGLE_DIALOG,
              //     payload: {
              //       activated: true,
              //       title: 'Erros',
              //       content: (
              //         <ul>
              //           {errors.map((obj, index) => {
              //             return <li key={index}>{obj}</li>;
              //           })}
              //         </ul>
              //       ),
              //       actions: true,
              //     },
              //   });
              // }
            }
          }
        } else if (payload.authErrors) {
          yield put({ type: appActions.IS_LOADING, payload: null });
          const cookieName: any = yield select(getCookieName);
          Router.push('/signin');
          yield removeCookie(cookieName);
        }
      }
    }
  );
}

export function* handleSuccess() {
  yield takeEvery(
    appActions.HANDLE_SUCCESS,
    function* ({ payload }: actionHandleSuccess) {
      if (payload && payload.messages) {
        yield put({ type: appActions.IS_LOADING, payload: null });
        if (Array.isArray(payload.messages)) {
          const count = payload.messages.length;
          if (count === 1) {
            yield put({
              type: appActions.TOGGLE_SNACKBAR,
              payload: {
                activated: true,
                message: payload.messages[0],
                variant: 'success',
              },
            });
          } else if (count >= 1) {
            yield put({
              type: appActions.TOGGLE_DIALOG,
              payload: {
                activated: true,
                title: 'Sucesso!',
                actions: true,
                content: (
                  <ul>
                    {payload.messages.map((obj, index) => (
                      <li key={index}>{obj}</li>
                    ))}
                  </ul>
                ),
              },
            });
          }
        }
      }
    }
  );
}

export default function* rootSaga() {
  yield all([spawn(handleErrors), spawn(handleSuccess)]);
}
