import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../../helpers/api';
import appActions from '../../app/actions';
import plansActions from '../plans/actions';
import actions from './actions';
import type {
  actionActivateGroupRequest,
  actionDeleteGroupRequest,
  actionFetchGroupPlansListRequest,
  actionFetchGroupPlansListSuccess,
  actionFetchGroupRequest,
  actionFetchGroupsListRequest,
  actionInactivateGroupRequest,
  actionInsertGroupRequest,
  actionUnlinkGroupPlanRequest,
  actionUpdateGroupRequest,
} from './types';

export const GroupsList = (state: any) => state.Groups.groupsList;

export function* fetchGroupsListRequest() {
  yield takeEvery(
    actions.FETCH_GROUPS_LIST_REQUEST,
    function* ({ payload, setLoading }: actionFetchGroupsListRequest) {
      try {
        const groupsList: any = yield select(GroupsList);

        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchGroups, payload);
        if (typeof setLoading === 'function') {
          setLoading(false);
        }

        if ((result.meta && result.meta.current_page === 1) || !groupsList.data) {
          yield put({
            type: actions.FETCH_GROUPS_LIST_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_GROUPS_LIST_SUCCESS,
            payload: {
              ...groupsList,
              data: [...groupsList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchGroupRequest() {
  yield takeEvery(
    actions.FETCH_GROUP_REQUEST,
    function* ({ payload }: actionFetchGroupRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.fetchGroup, payload);
        yield put({ type: actions.FETCH_GROUP_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertGroupRequest() {
  yield takeEvery(
    actions.INSERT_GROUP_REQUEST,
    function* ({ payload, callback }: actionInsertGroupRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.insertGroup, payload);
        yield put({
          type: actions.INSERT_GROUP_SUCCESS,
          payload: result,
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* updateGroupRequest() {
  yield takeEvery(
    actions.UPDATE_GROUP_REQUEST,
    function* ({ id, payload, callback }: actionUpdateGroupRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.updateGroup, id, payload);
        yield put({ type: actions.UPDATE_GROUP_SUCCESS, payload: result });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* deleteGroupRequest() {
  yield takeEvery(
    actions.DELETE_GROUP_REQUEST,
    function* ({ payload, callback }: actionDeleteGroupRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.deleteGroup, payload);
        yield put({
          type: actions.DELETE_GROUP_SUCCESS,
          payload: {},
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchGroupPlansListRequest() {
  yield takeEvery(
    actions.FETCH_GROUP_PLANS_LIST_REQUEST,
    function* ({ id }: actionFetchGroupPlansListRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.fetchGroupPlansList, id);

        yield put({ type: appActions.IS_LOADING, payload: false });

        yield put({
          type: actions.FETCH_GROUP_PLANS_LIST_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* unlinkGroupPlanRequest() {
  yield takeEvery(
    actions.UNLINK_GROUP_PLAN_REQUEST,
    function* ({ payload, callback }: actionUnlinkGroupPlanRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.unlinkPlan, payload);
        yield put({
          type: actions.UNLINK_GROUP_PLAN_SUCCESS,
          id: payload.primeiroid,
          payload: result,
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* unlinkPlanSuccess() {
  yield takeEvery(
    actions.UNLINK_GROUP_PLAN_SUCCESS,
    function* ({ id }: actionFetchGroupPlansListSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          title: 'Plano removido',
          message: 'Seu plano foi removido com sucesso.',
        },
      });
      yield put({
        type: actions.FETCH_GROUP_PLANS_LIST_REQUEST,
        id,
        payload: { page: 1 },
      });
    }
  );
}

export function* inactivateGroupRequest() {
  yield takeEvery(
    actions.INACTIVATE_GROUP_REQUEST,
    function* ({
      payload,
      inactivatePlans,
      callback,
    }: actionInactivateGroupRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(
          api.inactivateGroup,
          payload,
          inactivatePlans
        );
        yield put({
          type: actions.INACTIVATE_GROUP_SUCCESS,
          payload: result,
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* activateGroupRequest() {
  yield takeEvery(
    actions.ACTIVATE_GROUP_REQUEST,
    function* ({ payload, callback }: actionActivateGroupRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.activateGroup, payload);
        yield put({
          type: actions.ACTIVATE_GROUP_SUCCESS,
          payload: result,
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertGroupSuccess() {
  yield takeEvery(actions.INSERT_GROUP_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Grupo criado',
        message: 'Seu grupo foi criado com sucesso.',
      },
    });
    // yield put({
    //   type: actions.FETCH_GROUPS_LIST_REQUEST,
    //   payload: { page: 1 },
    // });
    yield put({
      type: plansActions.FETCH_PLANSGROUPS_REQUEST,
      payload: { page: 1 },
    });
  });
}

export function* updateGroupSuccess() {
  yield takeEvery(actions.UPDATE_GROUP_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Grupo editado',
        message: 'Seu grupo foi editado com sucesso.',
      },
    });
    // yield put({
    //   type: actions.FETCH_GROUPS_LIST_REQUEST,
    //   payload: { page: 1 },
    // });
    yield put({
      type: plansActions.FETCH_PLANSGROUPS_REQUEST,
      payload: { page: 1 },
    });
  });
}

export function* deleteGroupSuccess() {
  yield takeEvery(actions.DELETE_GROUP_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Grupo excluído',
        message: 'Seu grupo foi excluído com sucesso.',
      },
    });
    // yield put({
    //   type: actions.FETCH_GROUPS_LIST_REQUEST,
    //   payload: { page: 1 },
    // });
    yield put({
      type: plansActions.FETCH_PLANSGROUPS_REQUEST,
      payload: { page: 1 },
    });
  });
}

export function* inactivateGroupSuccess() {
  yield takeEvery(actions.INACTIVATE_GROUP_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Grupo inativado',
        message: 'Seu grupo foi inativado com sucesso.',
      },
    });
    // yield put({
    //   type: actions.FETCH_GROUPS_LIST_REQUEST,
    //   payload: { page: 1 },
    // });
    yield put({
      type: plansActions.FETCH_PLANSGROUPS_REQUEST,
      payload: { page: 1 },
    });
  });
}

export function* activateGroupSuccess() {
  yield takeEvery(actions.ACTIVATE_GROUP_SUCCESS, function* () {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Grupo ativado',
        message: 'Seu grupo foi ativado com sucesso.',
      },
    });
    // yield put({
    //   type: actions.FETCH_GROUPS_LIST_REQUEST,
    //   payload: { page: 1 },
    // });
    yield put({
      type: plansActions.FETCH_PLANSGROUPS_REQUEST,
      payload: { page: 1 },
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(fetchGroupsListRequest),
    spawn(fetchGroupRequest),
    spawn(fetchGroupPlansListRequest),
    spawn(insertGroupRequest),
    spawn(updateGroupRequest),
    spawn(deleteGroupRequest),
    spawn(inactivateGroupRequest),
    spawn(activateGroupRequest),
    spawn(unlinkGroupPlanRequest),
    fork(insertGroupSuccess),
    fork(updateGroupSuccess),
    fork(deleteGroupSuccess),
    fork(inactivateGroupSuccess),
    fork(activateGroupSuccess),
    fork(unlinkPlanSuccess),
  ]);
}
