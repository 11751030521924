import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import actions from './actions';
import type {
  actionDeleteErrorCommentRequest,
  actionFetchDashboardRequest,
  actionFetchErrorsListRequest,
  actionPostErrorCommentRequest,
  Success,
} from './types';

export const ErrorsList = (state: any) => state.Gaps.errorsList;

export function* fetchErrorsListRequest() {
  yield takeEvery(
    actions.FETCH_ERRORS_LIST_REQUEST,
    function* ({ payload, setLoading }: actionFetchErrorsListRequest) {
      try {
        const errorsList: any = yield select(ErrorsList);
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchErrors, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        yield put({
          type: actions.FETCH_ERRORS_LIST_SUCCESS,
          payload: result,
        });
        /* isso aqui é se for listagem
        if (
          (result.meta && result.meta.current_page === 1) ||
          !errorsList.data
        ) {
          yield put({
            type: actions.FETCH_ERRORS_LIST_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_ERRORS_LIST_SUCCESS,
            payload: {
              ...errorsList,
              data: [...errorsList.data, ...result.data],
              extra: result.extra,
              meta: result.meta,
            },
          });
        }*/
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchDashboardRequest() {
  yield takeEvery(
    actions.FETCH_DASHBOARD_REQUEST,
    function* ({ payload }: actionFetchDashboardRequest) {
      try {
        const result: any = yield call(api.fetchDashboard, payload);
        yield put({
          type: actions.FETCH_DASHBOARD_SUCCESS,
          payload: result.data,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* postErrorCommentRequest() {
  yield takeEvery(
    actions.POST_ERROR_COMMENT_REQUEST,
    function* ({
      payload,
      filters,
      setLoading,
      callback,
    }: actionPostErrorCommentRequest) {
      try {
        if (typeof setLoading === 'function') {
          setLoading({ type: 'post', value: true });
        }
        const result: any = yield call(api.postComment, payload);
        if (result && typeof setLoading === 'function') {
          setLoading({ type: 'post', value: false });
        }
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.POST_ERROR_COMMENT_SUCCESS,
          payload: { ...result, filters },
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* deleteErrorCommentRequest() {
  yield takeEvery(
    actions.DELETE_ERROR_COMMENT_REQUEST,
    function* ({
      payload,
      filters,
      setLoading,
      callback,
    }: actionDeleteErrorCommentRequest) {
      try {
        if (typeof setLoading === 'function') {
          setLoading({ id: payload.id, type: 'delete', value: true });
        }
        const result: any = yield call(api.deleteComment, payload);
        if (result && typeof setLoading === 'function') {
          setLoading({ id: null, type: 'delete', value: false });
        }
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.DELETE_ERROR_COMMENT_SUCCESS,
          payload: { ...result, filters },
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* postErrorCommentSuccess() {
  yield takeEvery(
    actions.POST_ERROR_COMMENT_SUCCESS,
    function* ({ payload }: Success) {
      if (payload.messages) {
        yield put({
          type: appActions.TOGGLE_SNACKBAR,
          payload: {
            activated: true,
            message: payload.messages[0],
            time: 2000,
          },
        });
        yield put({
          type: actions.FETCH_ERRORS_LIST_REQUEST,
          payload: { ...payload.filters, page: 1 },
        });
      }
    }
  );
}

export function* deleteErrorCommentSuccess() {
  yield takeEvery(
    actions.DELETE_ERROR_COMMENT_SUCCESS,
    function* ({ payload }: Success) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          message: 'Comentário excluído com sucesso',
          time: 2000,
        },
      });
      console.log(payload.filters);
      yield put({
        type: actions.FETCH_ERRORS_LIST_REQUEST,
        payload: { ...payload.filters, page: 1 },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchDashboardRequest),
    spawn(fetchErrorsListRequest),
    spawn(postErrorCommentRequest),
    spawn(deleteErrorCommentRequest),
    fork(postErrorCommentSuccess),
    fork(deleteErrorCommentSuccess),
  ]);
}
