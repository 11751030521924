import { all, call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import actions from './actions';
import type {
  actionConvertIndicationRequest,
  actionFetchChurnRequest,
  actionFetchDownsellRequest,
  actionFetchExemptRequest,
  actionFetchIncomeRequest,
  actionFetchIndicationsRequest,
  actionFetchNewClientsRequest,
  actionFetchNPSRequest,
  actionFetchResultsRequest,
  actionFetchUpsellRequest,
  ListType,
  ResultsType,
} from './types';

export const Churn = (state: any) => state.Results.churn;
export const Downsell = (state: any) => state.Results.downsell;
export const DownsellRecursos = (state: any) => state.Results.downsellRecursos;
export const Upsell = (state: any) => state.Results.upsell;
export const UpsellRecursos = (state: any) => state.Results.upsellRecursos;
export const Income = (state: any) => state.Results.income;
export const Exempt = (state: any) => state.Results.exempt;
export const NewClients = (state: any) => state.Results.newClients;
export const NPS = (state: any) => state.Results.nps;
export const Indications = (state: any) => state.Results.indications;

export function* fetchResultsRequest() {
  yield takeEvery(
    actions.FETCH_RESULTS_REQUEST,
    function* ({ payload }: actionFetchResultsRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: ResultsType = yield call(api.fetchResults, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.FETCH_RESULTS_SUCCESS,
          payload: result.data,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchUpsellRequest() {
  yield takeEvery(
    actions.FETCH_UPSELL_REQUEST,
    function* ({ payload, setLoading }: actionFetchUpsellRequest) {
      try {
        const upsell: ListType = yield select(Upsell);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchUpsell, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !upsell.data) {
          yield put({
            type: actions.FETCH_UPSELL_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_UPSELL_SUCCESS,
            payload: {
              ...upsell,
              data: [...upsell.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchUpsellRecursosRequest() {
  yield takeEvery(
    actions.FETCH_UPSELL_RECURSOS_REQUEST,
    function* ({ payload, setLoading }: actionFetchUpsellRequest) {
      try {
        const upsell: ListType = yield select(UpsellRecursos);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchUpsellRecursos, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !upsell.data) {
          yield put({
            type: actions.FETCH_UPSELL_RECURSOS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_UPSELL_RECURSOS_SUCCESS,
            payload: {
              ...upsell,
              data: [...upsell.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchDownsellRequest() {
  yield takeEvery(
    actions.FETCH_DOWNSELL_REQUEST,
    function* ({ payload, setLoading }: actionFetchDownsellRequest) {
      try {
        const downsell: ListType = yield select(Downsell);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchDownsell, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !downsell.data) {
          yield put({
            type: actions.FETCH_DOWNSELL_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_DOWNSELL_SUCCESS,
            payload: {
              ...downsell,
              data: [...downsell.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchDownsellRecursosRequest() {
  yield takeEvery(
    actions.FETCH_DOWNSELL_RECURSOS_REQUEST,
    function* ({ payload, setLoading }: actionFetchDownsellRequest) {
      try {
        const downsell: ListType = yield select(DownsellRecursos);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchDownsellRecursos, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !downsell.data) {
          yield put({
            type: actions.FETCH_DOWNSELL_RECURSOS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_DOWNSELL_RECURSOS_SUCCESS,
            payload: {
              ...downsell,
              data: [...downsell.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchChurnRequest() {
  yield takeEvery(
    actions.FETCH_CHURN_REQUEST,
    function* ({ payload, setLoading }: actionFetchChurnRequest) {
      try {
        const churn: ListType = yield select(Churn);
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchChurn, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        if ((result.meta && result.meta.current_page === 1) || !churn.data) {
          yield put({
            type: actions.FETCH_CHURN_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_CHURN_SUCCESS,
            payload: {
              ...churn,
              data: [...churn.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchIncomeRequest() {
  yield takeEvery(
    actions.FETCH_INCOME_REQUEST,
    function* ({ payload, setLoading }: actionFetchIncomeRequest) {
      try {
        const income: ListType = yield select(Income);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchIncome, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !income.data) {
          yield put({
            type: actions.FETCH_INCOME_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_INCOME_SUCCESS,
            payload: {
              ...income,
              data: [...income.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchExemptRequest() {
  yield takeEvery(
    actions.FETCH_EXEMPT_REQUEST,
    function* ({ payload, setLoading }: actionFetchExemptRequest) {
      try {
        const exempt: ListType = yield select(Exempt);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchExempt, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !exempt.data) {
          yield put({
            type: actions.FETCH_EXEMPT_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_EXEMPT_SUCCESS,
            payload: {
              ...exempt,
              data: [...exempt.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchNewClientsRequest() {
  yield takeEvery(
    actions.FETCH_NEWCLIENTS_REQUEST,
    function* ({ payload, setLoading }: actionFetchNewClientsRequest) {
      try {
        const newClients: ListType = yield select(NewClients);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchNewClients, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !newClients.data) {
          yield put({
            type: actions.FETCH_NEWCLIENTS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_NEWCLIENTS_SUCCESS,
            payload: {
              ...newClients,
              data: [...newClients.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchNPSRequest() {
  yield takeEvery(
    actions.FETCH_NPS_REQUEST,
    function* ({ payload, setLoading }: actionFetchNPSRequest) {
      try {
        const nps: ListType = yield select(NPS);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchNPS, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !nps.data) {
          yield put({
            type: actions.FETCH_NPS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_NPS_SUCCESS,
            payload: {
              ...nps,
              data: [...nps.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchIndicationsRequest() {
  yield takeEvery(
    actions.FETCH_INDICATIONS_REQUEST,
    function* ({ payload, setLoading }: actionFetchIndicationsRequest) {
      try {
        const indications: ListType = yield select(Indications);

        // yield put({ type: appActions.IS_LOADING, payload: true });
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: ListType = yield call(api.fetchIndications, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        // yield put({ type: appActions.IS_LOADING, payload: false });

        if ((result.meta && result.meta.current_page === 1) || !indications.data) {
          yield put({
            type: actions.FETCH_INDICATIONS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_INDICATIONS_SUCCESS,
            payload: {
              ...indications,
              data: [...indications.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* convertIndication() {
  yield takeEvery(
    actions.CONVERT_INDICATION,
    function* ({ id, payload, callback, filters }: actionConvertIndicationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.convertIndication, id, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });

        if (callback && typeof callback === 'function') {
          callback();
        }
        yield put({
          type: appActions.TOGGLE_SNACKBAR,
          payload: {
            activated: true,
            title: 'Indicação convertida',
            message: 'Indicação convertida com sucesso',
          },
        });
        yield put({
          type: actions.FETCH_INDICATIONS_REQUEST,
          payload: { page: 1, ...filters },
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchResultsRequest),
    spawn(fetchUpsellRequest),
    spawn(fetchUpsellRecursosRequest),
    spawn(fetchDownsellRequest),
    spawn(fetchDownsellRecursosRequest),
    spawn(fetchChurnRequest),
    spawn(fetchIncomeRequest),
    spawn(fetchExemptRequest),
    spawn(fetchNewClientsRequest),
    spawn(fetchNPSRequest),
    spawn(fetchIndicationsRequest),
    spawn(convertIndication),
  ]);
}
