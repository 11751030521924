import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../redux/rootSaga';

import graficosSlice from './graficos/graficosSlice';
import dashboardSlice from './dashboard/dashboardSlice';
import indicacoesSlice from './perfil/indicacoes/indicacoesSlice';
import App from '../redux/app/reducer';
import Auth from '../redux/auth/reducer';
import Gaps from '../redux/gaps/reducer';
import Results from '../redux/results/reducer';
import Plans from '../redux/settings/plans/reducer';
import Resources from '../redux/settings/resources/reducer';
import Cities from '../redux/cities/reducer';
import Groups from '../redux/settings/groups/reducer';
import PartnersTypes from '../redux/settings/partnersTypes/reducer';
import Partners from '../redux/users/reducer';
import UserExperience from '../redux/users/experience/reducer';
import Goals from '../redux/goals/reducer';
import News from '../redux/settings/news/reducer';
import Setup from '../redux/settings/setupInicial/reducer';
import Notifications from '../redux/settings/notifications/reducer';
import Emails from '../redux/emails/reducer';
import UsersAccess from '../redux/settings/usersAccess/reducer';

import { createWrapper } from 'next-redux-wrapper';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunkMiddleware];

const devMode = process.env.NODE_ENV === 'development';

const makeStore = () =>
  configureStore({
    reducer: {
      Graficos: graficosSlice.reducer,
      Dashboard: dashboardSlice.reducer,
      IndicacoesPerfil: indicacoesSlice.reducer,
      App,
      Auth,
      Gaps,
      Results,
      Plans,
      Resources,
      Cities,
      Groups,
      PartnersTypes,
      Partners,
      Goals,
      UserExperience,
      News,
      Setup,
      Notifications,
      Emails,
      UsersAccess,
    },
    middleware: middlewares,
    devTools: devMode,
  });

const store = makeStore();

sagaMiddleware.run(rootSaga);

export type DispatchFunction = ReturnType<typeof store.dispatch>;
export type State = ReturnType<typeof store.getState>;

export const wrapper = createWrapper(makeStore);

export type ThunkStatefulAction<ReturnType = void, Extra = unknown> = ThunkAction<
  ReturnType,
  State,
  Extra,
  Action<string>
>;

export default store;
