import { Button, Typography } from '@l_ine/core';
import Router from 'next/router';
import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import actions from './actions';
import experienceActions from './experience/actions';
import plansActions from '../settings/plans/actions';
import type {
  actionActivatePartnerRequest,
  actionBlockPartnerRequest,
  actionChangePartnerPlanRequest,
  actionDeleteDepositionRequest,
  actionDeleteObservationRequest,
  actionEditarParceiroRequest,
  actionEditarParceiroSuccess,
  actionExemptChargeRequest,
  actionFetchChargesRequest,
  actionFetchDepositionsRequest,
  actionFetchObservationsRequest,
  actionFetchPartnerRequest,
  actionFetchPartnersClientsRequest,
  actionFetchPartnersListRequest,
  actionFetchUsersRequest,
  actionInactivateClientRequest,
  actionInsertPartnerRequest,
  actionLinkClientsRequest,
  // actionPatchPartnerRequest,
  actionPaydChargeRequest,
  actionPostDepositionRequest,
  actionPostObservationRequest,
  actionPostReenviarConviteRequest,
  actionSeparateChargeRequest,
  actionTransferClientRequest,
  actionTransformPartnerClientRequest,
  actionUnblockPartnerRequest,
  actionUndoExemptChargeRequest,
  actionUnlinkClientRequest,
  actionUpdateDepositionRequest,
  actionUpdateObservationRequest,
  Success,
} from './types';

export const PartnersList = (state: any) => state.Partners.partnersList;
export const PartnersClientsList = (state: any) =>
  state.Partners.PartnersClientsList;
export const UsersList = (state: any) => state.Partners.usersList;
export const Charges = (state: any) => state.Partners.chargesList;
export const Depositions = (state: any) => state.Partners.depositions;
export const Observations = (state: any) => state.Partners.observations;
const getPartner = (state: any) => state.Partners.partner;

export function* fetchPartnersListRequest() {
  yield takeEvery(
    actions.FETCH_PARTNERS_LIST_REQUEST,
    function* ({ payload, setLoading }: actionFetchPartnersListRequest) {
      try {
        const partnersList: any = yield select(PartnersList);
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchPartners, payload);

        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }

        //TODO
        //! EM OBRAS !//

        // const customizer = (
        //   value: any,
        //   srcValue: any,
        //   key: string,
        //   object: any,
        //   source: any
        // ) => {
        //   console.log('costumizaer', object, source);
        // };

        // yield put({
        //   type: actions.FETCH_PARTNERS_LIST_SUCCESS,
        //   payload: data,
        // });
        if (payload.page == 1) {
          yield put({
            type: actions.FETCH_PARTNERS_LIST_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_PARTNERS_LIST_SUCCESS,
            payload: {
              ...result,
              data: [...partnersList.data, ...result.data],
            },
          });
          // yield put({
          //   type: actions.FETCH_PARTNERS_LIST_SUCCESS,
          //   payload: {
          //     ...data,
          //     data: _.mergeWith(partnersList.data, data.data, (value: any, srcValue: any, key: string, object: any, source: any) => {
          //       if (!(payload.page == 1)) {
          //         console.log(value.concat(srcValue))
          //         return value.concat(srcValue);
          //       }
          //       return undefined;
          //     })
          //   }
          // });
        }
        // if (
        //   (data.meta && data.meta.current_page === 1) ||
        //   !partnersList.data
        // ) {
        //   yield put({
        //     type: actions.FETCH_PARTNERS_LIST_SUCCESS,
        //     payload: data,
        //   });
        // }
        // else {
        //   yield put({
        //     type: actions.FETCH_PARTNERS_LIST_SUCCESS,
        //     payload: {
        //       ...partnersList,
        //       data: [...partnersList.data, ...data.data],
        //       extra: data.extra,
        //       meta: data.meta,
        //     },
        //   });
        // }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchUsersRequest() {
  yield takeEvery(
    actions.FETCH_USERS_REQUEST,
    function* ({ id, payload, setLoading }: actionFetchUsersRequest) {
      try {
        const usersList: any = yield select(UsersList);
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchUsers, id, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        if ((result.meta && result.meta.current_page === 1) || !usersList.data) {
          yield put({
            type: actions.FETCH_USERS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_USERS_SUCCESS,
            payload: {
              ...usersList,
              data: [...usersList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchPartnersClientsRequest() {
  yield takeEvery(
    actions.FETCH_PARTNERS_CLIENTS_REQUEST,
    function* ({ payload, setLoading }: actionFetchPartnersClientsRequest) {
      try {
        const partnersClientsList: any = yield select(PartnersClientsList);
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchPartners, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        if (
          (result.meta && result.meta.current_page === 1) ||
          !partnersClientsList.result
        ) {
          yield put({
            type: actions.FETCH_PARTNERS_CLIENTS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_PARTNERS_CLIENTS_SUCCESS,
            payload: {
              ...partnersClientsList,
              data: [...partnersClientsList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchPartnerRequest() {
  yield takeEvery(
    actions.FETCH_PARTNER_REQUEST,
    function* ({ payload }: actionFetchPartnerRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.fetchPartner, payload);
        yield put({
          type: actions.FETCH_PARTNER_SUCCESS,
          payload: result,
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchChargesRequest() {
  yield takeEvery(
    actions.FETCH_CHARGES_REQUEST,
    function* ({ id, payload }: actionFetchChargesRequest) {
      try {
        const result: any = yield call(api.fetchCharges, id, payload);
        yield put({
          type: actions.FETCH_CHARGES_SUCCESS,
          payload: result,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertPartnerRequest() {
  yield takeEvery(
    actions.INSERT_PARTNER_REQUEST,
    function* ({ payload, callback }: actionInsertPartnerRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.insertPartner, payload);
        yield put({
          type: actions.INSERT_PARTNER_SUCCESS,
          payload: payload,
          result
        });
        if (result && typeof callback === 'function') {
          //callback({ open: true, type: 'linkEmail', id: result.codigo });
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* inactivateClientRequest() {
  yield takeEvery(
    actions.INACTIVATE_CLIENT_REQUEST,
    function* ({
      payload,
      partnerId,
      callback,
      filters,
    }: actionInactivateClientRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.inactivateClient, payload);

        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({
          type: actions.INACTIVATE_CLIENT_SUCCESS,
          payload: { ...payload, partnerId, filters },
        });
        if (typeof callback === 'function') {
          callback();
        }
      } catch (error) {
        //@ts-ignore
        if (error.possui_clientes) {
          yield put({
            type: appActions.TOGGLE_SNACKBAR,
            payload: {
              activated: true,
              variant: 'danger',
              title: 'Parceiro com clientes ativos',
              time: 3000,
              message: (
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: '2em' }}>
                    <Typography variant='bodyXS'>
                      Não é possível inativar um parceiro que possua clientes ativos,{' '}
                      <br />
                      inative seus clientes e tente novamente
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Button
                      data-testid='clientes--list--menu-ativarInativar--snackbar-verFaturas'
                      variant='neutral'
                      onClick={() => {
                        Router.push(`/clientes/perfil?id=${partnerId}&vinculados=1`);
                        if (typeof callback === 'function') {
                          callback();
                        }
                      }}
                    >
                      Ir para clientes vinculados
                    </Button>
                  </div>
                </div>
              ),
            },
          });
        } else {
          yield put({
            type: appActions.HANDLE_ERRORS,
            payload: error,
          });
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* activatePartnerRequest() {
  yield takeEvery(
    actions.ACTIVATE_PARTNER_REQUEST,
    function* ({ payload }: actionActivatePartnerRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.activatePartner, payload.id);

        yield put({
          type: actions.ACTIVATE_PARTNER_SUCCESS,
          payload: payload,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
      yield put({ type: appActions.IS_LOADING, payload: false });
    }
  );
}

export function* unblockPartnerRequest() {
  yield takeEvery(
    actions.UNBLOCK_PARTNER_REQUEST,
    function* ({ payload, partnerId, callback }: actionUnblockPartnerRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.unblockPartner, payload);

        yield put({
          type: actions.UNBLOCK_PARTNER_SUCCESS,
          payload: { partnerId },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.IS_LOADING, payload: false });
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        if (typeof callback === 'function') {
          callback();
        }
      }
    }
  );
}

export function* blockPartnerRequest() {
  yield takeEvery(
    actions.BLOCK_PARTNER_REQUEST,
    function* ({ payload, partnerId, callback }: actionBlockPartnerRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.blockPartner, payload);
        yield put({
          type: actions.BLOCK_PARTNER_SUCCESS,
          payload: {
            ...result,
            partnerId,
            tipoBloqueio: payload.tipoBloqueio,
          },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        if (typeof callback === 'function') {
          callback();
        }
      }
    }
  );
}

export function* linkClientsRequest() {
  yield takeEvery(
    actions.LINK_CLIENTS_REQUEST,
    function* ({ id, payload, callback }: actionLinkClientsRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.linkClients, id, payload);
        yield put({
          type: actions.LINK_CLIENTS_SUCCESS,
          payload: { id },
        });
        if (callback && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* unlinkClientRequest() {
  yield takeEvery(
    actions.UNLINK_CLIENT_REQUEST,
    function* ({ id, partnerId, callback }: actionUnlinkClientRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.unlinkClient, id);
        yield put({
          type: actions.UNLINK_CLIENT_SUCCESS,
          payload: { partnerId },
        });
        if (callback && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* transferClientRequest() {
  yield takeEvery(
    actions.TRANSFER_CLIENT_REQUEST,
    function* ({ clientId, destinoId, id, callback }: actionTransferClientRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.transferClient, clientId, destinoId);
        yield put({
          type: actions.TRANSFER_CLIENT_SUCCESS,
          payload: { ...result, id },
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

// export function* patchPartnerRequest() {
//   yield takeEvery(
//     actions.PATCH_PARTNER_REQUEST,
//     function* ({ id, payload, callback, cancelSuccess }: actionPatchPartnerRequest) {
//       try {
//         yield put({ type: appActions.IS_LOADING, payload: true });

//         const partner: any = yield select(getPartner);
//         const result: any = yield call(api.patchPartner, id, payload);
//         if (result && typeof callback === 'function') {
//           callback();
//         }
//         if (!cancelSuccess) {
//           yield put({
//             type: actions.PATCH_PARTNER_SUCCESS,
//             payload: result,
//           });
//           yield put({
//             type: actions.FETCH_PARTNER_REQUEST,
//             payload: partner.codigo,
//           });
//         }
//         yield put({ type: appActions.IS_LOADING, payload: false });
//       } catch (error) {
//         yield put({ type: appActions.HANDLE_ERRORS, payload: error });
//       }
//     }
//   );
// }

export function* postReenviarConviteRequest() {
  yield takeEvery(
    actions.POST_REENVIAR_CONVITE_REQUEST,
    function* ({ id, payload, callback }: actionPostReenviarConviteRequest) {
      try {
       const result: Record<string, any> = yield call(api.postReenviarConvite, id, payload);

        yield put({
          type: appActions.TOGGLE_SNACKBAR,
          payload: {
            activated: true,
            title: 'Email enviado com sucesso',
            time: 15000,
            action: () => {
              if(navigator.clipboard) {
                navigator.clipboard.writeText(result.data)
              } else {
                alert('Não funciona em sites com conexão "Não segura"')
              }
            },
            actionText: 'Copiar link'
          },
        });

        if (typeof callback === 'function') {
          callback();
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* changePartnerPlanRequest() {
  yield takeEvery(
    actions.CHANGE_PLAN_REQUEST,
    function* ({
      codigoClienteParceiro,
      payload,
      callback,
    }: actionChangePartnerPlanRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.changePartnerPlan, codigoClienteParceiro, payload);

        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: appActions.TOGGLE_SNACKBAR,
          payload: {
            activated: true,
            title: 'Plano alterado',
            message: 'Plano alterado com sucesso',
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });

        yield put(plansActions.fetchMeuPlanoRequest(codigoClienteParceiro));
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* exemptChargeRequest() {
  yield takeEvery(
    actions.EXEMPT_CHARGE_REQUEST,
    function* ({ payload, callback, partnerId }: actionExemptChargeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.exemptCharge, payload);
        yield put({
          type: actions.EXEMPT_CHARGE_SUCCESS,
          payload: { ...result, partnerId },
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* markAsPaydRequest() {
  yield takeEvery(
    actions.PAYD_CHARGE_REQUEST,
    function* ({ payload, callback, partnerId }: actionPaydChargeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.markAsPayd, payload);
        yield put({
          type: actions.PAYD_CHARGE_SUCCESS,
          payload: partnerId,
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* undoExemptChargeRequest() {
  yield takeEvery(
    actions.UNDOEXEMPT_CHARGE_REQUEST,
    function* ({ payload, callback, partnerId }: actionUndoExemptChargeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.undoExemptCharge, payload);
        yield put({
          type: actions.UNDOEXEMPT_CHARGE_SUCCESS,
          payload: partnerId,
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* separateChargeRequest() {
  yield takeEvery(
    actions.SEPARATE_CHARGE_REQUEST,
    function* ({ payload, callback, partnerId }: actionSeparateChargeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.postSeparateCharge, payload);
        yield put({
          type: actions.SEPARATE_CHARGE_SUCCESS,
          payload: { ...result, partnerId },
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchDepositionsRequest() {
  yield takeEvery(
    actions.FETCH_DEPOSITIONS_REQUEST,
    function* ({ payload, setLoading }: actionFetchDepositionsRequest) {
      try {
        if (typeof setLoading === 'function') {
          setLoading(true);
        }

        const depositionsList: any = yield select(Depositions);
        const result: any = yield call(api.fetchDepositions, payload);
        if (
          (result.meta && result.meta.current_page === 1) ||
          !depositionsList.data
        ) {
          yield put({
            type: actions.FETCH_DEPOSITIONS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_DEPOSITIONS_SUCCESS,
            payload: {
              ...result,
              data: [...depositionsList.data, ...result.data],
            },
          });
        }
        if (typeof setLoading === 'function') {
          setLoading(false);
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* postDepositionRequest() {
  yield takeEvery(
    actions.POST_DEPOSITION_REQUEST,
    function* ({ payload, callback, partnerId }: actionPostDepositionRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.postDeposition, payload);
        yield put({
          type: actions.POST_DEPOSITION_SUCCESS,
          payload: { ...result, partnerId },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* updateDepositionRequest() {
  yield takeEvery(
    actions.UPDATE_DEPOSITION_REQUEST,
    function* ({ id, payload, callback, partnerId }: actionUpdateDepositionRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.updateDeposition, id, payload);
        yield put({
          type: actions.UPDATE_DEPOSITION_SUCCESS,
          payload: { ...result, partnerId },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* deleteDepositionRequest() {
  yield takeEvery(
    actions.DELETE_DEPOSITION_REQUEST,
    function* ({ id, callback, partnerId }: actionDeleteDepositionRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.deleteDeposition, id);
        yield put({
          type: actions.DELETE_DEPOSITION_SUCCESS,
          payload: { ...result, partnerId },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* transformPartnerClientRequest() {
  yield takeEvery(
    actions.TRANSFORM_PARTNERCLIENT_REQUEST,
    function* ({ id, payload, callback }: actionTransformPartnerClientRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.transformPartnerClient, id, payload);
        yield put({
          type: actions.TRANSFORM_PARTNERCLIENT_SUCCESS,
          payload: { ...result, id },
        });
        if (typeof callback === 'function') {
          callback();
        }

        yield put(actions.fetchPartner(id));
        yield put(plansActions.fetchMeuPlanoRequest(id));
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchObservationsRequest() {
  yield takeEvery(
    actions.FETCH_OBSERVATIONS_REQUEST,
    function* ({ payload, setLoading }: actionFetchObservationsRequest) {
      try {
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const observationsList: any = yield select(Observations);
        const result: any = yield call(api.fetchObservations, payload);
        if (
          (result.meta && result.meta.current_page === 1) ||
          !observationsList.data
        ) {
          yield put({
            type: actions.FETCH_OBSERVATIONS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_OBSERVATIONS_SUCCESS,
            payload: {
              ...observationsList,
              data: [...observationsList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
        if (typeof setLoading === 'function') {
          setLoading(false);
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* postObservationRequest() {
  yield takeEvery(
    actions.POST_OBSERVATION_REQUEST,
    function* ({ payload, callback }: actionPostObservationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.postObservation, payload);
        yield put({
          type: actions.POST_OBSERVATION_SUCCESS,
          payload: { ...result, ...payload },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* updateObservationRequest() {
  yield takeEvery(
    actions.UPDATE_OBSERVATION_REQUEST,
    function* ({ id, payload, callback }: actionUpdateObservationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.updateObservation, id, payload);
        yield put({
          type: actions.UPDATE_OBSERVATION_SUCCESS,
          payload: { ...result, ...payload },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* deleteObservationRequest() {
  yield takeEvery(
    actions.DELETE_OBSERVATION_REQUEST,
    function* ({ id, partnerId, callback }: actionDeleteObservationRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.deleteObservation, id);
        yield put({
          type: actions.DELETE_OBSERVATION_SUCCESS,
          payload: { ...result, partnerId },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertPartnerSuccess() {
  yield takeEvery(actions.INSERT_PARTNER_SUCCESS, function* ({ payload, result }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: payload.parceiro ? 'Parceiro cadastrado' : 'Cliente cadastrado',
        message: `O ${
          payload.parceiro ? 'parceiro' : 'cliente'
        } foi cadastrado com sucesso.`,
        time: 15000,
        action: () => {
          if(navigator.clipboard) {
            navigator.clipboard.writeText(result.data)
          } else {
            alert('Não funciona em sites com conexão "Não segura"')
          }
        },
        actionText: 'Copiar link'
      },
    });
    yield put({
      type: actions.FETCH_PARTNERS_LIST_REQUEST,
      payload: { page: 1 },
    });
  });
}

export function* inactivateClientSuccess() {
  yield takeEvery(actions.INACTIVATE_CLIENT_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Cliente inativado',
        message: 'O cliente foi inativado com sucesso.',
      },
    });
    if (payload.partnerId) {
      yield put({
        type: actions.FETCH_PARTNERS_CLIENTS_REQUEST,
        payload: {
          all: true,
          'vinculados-ao-parceiro': payload.partnerId,
        },
      });
    } else {
      yield put({
        type: actions.FETCH_PARTNER_REQUEST,
        payload: payload.codigo,
      });
    }
    yield put({
      type: experienceActions.FETCH_HISTORIC_REQUEST,
      id: payload.codigo,
      payload: { page: 1 },
    });
  });
}

export function* activatePartnerSuccess() {
  yield takeEvery(actions.ACTIVATE_PARTNER_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Cliente ativado',
        message: 'O cliente foi ativado com sucesso.',
      },
    });
    yield put({
      type: actions.FETCH_PARTNER_REQUEST,
      payload: payload.id,
    });
    yield put({
      type: experienceActions.FETCH_HISTORIC_REQUEST,
      id: payload.id,
      payload: { page: 1 },
    });
  });
}

export function* unblockPartnerSuccess() {
  yield takeEvery(actions.UNBLOCK_PARTNER_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Cliente desbloqueado',
        message: 'O cliente foi desbloqueado com sucesso',
      },
    });
    if (payload && payload.partnerId) {
      yield put({
        type: actions.FETCH_PARTNER_REQUEST,
        payload: payload.partnerId,
      });
      yield put({
        type: actions.FETCH_PARTNERS_CLIENTS_REQUEST,
        payload: {
          all: true,
          'vinculados-ao-parceiro': payload.partnerId,
        },
      });
      yield put({
        type: experienceActions.FETCH_HISTORIC_REQUEST,
        id: payload.partnerId,
        payload: { page: 1 },
      });
    }
  });
}

export function* blockPartnerSuccess() {
  yield takeEvery(actions.BLOCK_PARTNER_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: payload.tipoBloqueio ? 'Empresa bloqueada' : 'Cliente bloqueado',
        message: payload.tipoBloqueio
          ? 'A empresa e clientes foram bloqueados com sucesso'
          : 'O cliente foi bloqueado com sucesso',
      },
    });
    if (payload && payload.partnerId) {
      yield put({
        type: actions.FETCH_PARTNER_REQUEST,
        payload: payload.partnerId,
      });
      yield put({
        type: actions.FETCH_PARTNERS_CLIENTS_REQUEST,
        payload: {
          all: true,
          'vinculados-ao-parceiro': payload.partnerId,
        },
      });
      yield put({
        type: experienceActions.FETCH_HISTORIC_REQUEST,
        id: payload.partnerId,
        payload: { page: 1 },
      });
    }
  });
}

export function* linkClientsSuccess() {
  yield takeEvery(actions.LINK_CLIENTS_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Clientes vinculados',
        message: 'Os clientes foram vinculados ao parceiro com sucesso.',
      },
    });
    // yield put({
    //   type: actions.FETCH_PARTNERS_LIST_REQUEST,
    //   payload: { page: 1 },
    // });
    yield put({
      type: actions.FETCH_PARTNERS_CLIENTS_REQUEST,
      payload: { all: true, 'vinculados-ao-parceiro': payload.id },
    });
    yield put({
      type: actions.FETCH_PARTNER_REQUEST,
      payload: payload.id,
    });
  });
}

export function* unlinkClientSuccess() {
  yield takeEvery(actions.UNLINK_CLIENT_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Cliente desvinculado',
        message: 'Cliente desvinculado com sucesso',
      },
    });
    yield put({
      type: actions.FETCH_PARTNERS_CLIENTS_REQUEST,
      payload: {
        all: true,
        'vinculados-ao-parceiro': payload.partnerId,
      },
    });
    yield put({
      type: actions.FETCH_PARTNER_REQUEST,
      payload: payload.partnerId,
    });
  });
}

export function* transferClientSuccess() {
  yield takeEvery(actions.TRANSFER_CLIENT_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Transferir cliente',
        message: payload.messages[0],
      },
    });
    yield put({
      type: actions.FETCH_PARTNERS_CLIENTS_REQUEST,
      payload: { all: true, 'vinculados-ao-parceiro': payload.id },
    });
  });
}

export function* patchPeopleSuccess() {
  yield takeEvery(actions.PATCH_PARTNER_SUCCESS, function* ({ payload }: Success) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Dados atualizados',
        message: 'Os dados foram atualizados com sucesso.',
      },
    });
  });
}

export function* exemptChargeSuccess() {
  yield takeEvery(actions.EXEMPT_CHARGE_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: payload?.messages[0].includes('excluída')
          ? 'Cobrança excluída'
          : 'Cobrança isentada',
        message: payload?.messages[0],
      },
    });
    yield put({
      type: actions.FETCH_CHARGES_REQUEST,
      id: payload.partnerId,
    });
    yield put({
      type: actions.FETCH_PARTNER_REQUEST,
      payload: payload.partnerId,
    });
  });
}

export function* undoExemptChargeSuccess() {
  yield takeEvery(
    actions.UNDOEXEMPT_CHARGE_SUCCESS,
    function* ({ payload }: Success) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          title: 'Isenção desfeita',
          message: 'Isenção desfeita com sucesso.',
        },
      });
      yield put({
        type: actions.FETCH_CHARGES_REQUEST,
        id: payload,
      });
    }
  );
}

export function* separateChargeSuccess() {
  yield takeEvery(actions.SEPARATE_CHARGE_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Cobrança avulsa',
        message: payload?.messages[0],
      },
    });
    yield put({
      type: actions.FETCH_CHARGES_REQUEST,
      id: payload.partnerId,
    });
  });
}

export function* markAsPaydSuccess() {
  yield takeEvery(actions.PAYD_CHARGE_SUCCESS, function* ({ payload }: Success) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Cobrança paga',
        message: 'A cobrança foi marcada como paga com sucesso.',
      },
    });
    yield put({
      type: actions.FETCH_CHARGES_REQUEST,
      id: payload,
    });
  });
}

export function* postDepositionSuccess() {
  yield takeEvery(actions.POST_DEPOSITION_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Depoimento cadastrado',
        message: 'Depoimento cadastrado com sucesso.',
      },
    });
    yield put({
      type: actions.FETCH_DEPOSITIONS_REQUEST,
      payload: { empresa: payload.partnerId, page: 1 },
    });
  });
}

export function* updateDepositionSuccess() {
  yield takeEvery(actions.UPDATE_DEPOSITION_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Depoimento editado',
        message: 'Depoimento editado com sucesso.',
      },
    });
    yield put({
      type: actions.FETCH_DEPOSITIONS_REQUEST,
      payload: { empresa: payload.partnerId, page: 1 },
    });
  });
}

export function* deleteDepositionSuccess() {
  yield takeEvery(actions.DELETE_DEPOSITION_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Depoimento excluído',
        message: 'Depoimento excluído com sucesso.',
      },
    });
    yield put({
      type: actions.FETCH_DEPOSITIONS_REQUEST,
      payload: { empresa: payload.partnerId, page: 1 },
    });
  });
}

export function* transformPartnerClientSuccess() {
  yield takeEvery(
    actions.TRANSFORM_PARTNERCLIENT_SUCCESS,
    function* ({ payload }: any) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          title: 'Cliente/parceiro transformado',
          message: 'Cliente/parceiro transformado com sucesso',
        },
      });
      yield put({
        type: actions.FETCH_PARTNER_REQUEST,
        payload: payload.id,
      });
      yield put({
        type: experienceActions.FETCH_HISTORIC_REQUEST,
        id: payload.id,
        payload: { page: 1 },
      });
    }
  );
}

export function* postObservationSuccess() {
  yield takeEvery(actions.POST_OBSERVATION_SUCCESS, function* ({ payload }: any) {
    console.log(payload);
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Observação cadastrada',
        message: payload.messages[0],
      },
    });
    yield put({
      type: actions.FETCH_OBSERVATIONS_REQUEST,
      payload: { empresa: payload.empresa, page: 1 },
    });
  });
}

export function* updateObservationSuccess() {
  yield takeEvery(actions.UPDATE_OBSERVATION_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Observação editada',
        message: payload.messages[0],
      },
    });
    yield put({
      type: actions.FETCH_OBSERVATIONS_REQUEST,
      payload: { empresa: payload.empresa, page: 1 },
    });
  });
}

export function* deleteObservationSuccess() {
  yield takeEvery(actions.DELETE_OBSERVATION_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Observação excluído',
        message: payload.messages[0],
      },
    });
    yield put({
      type: actions.FETCH_OBSERVATIONS_REQUEST,
      payload: { empresa: payload.partnerId, page: 1 },
    });
  });
}

export function* patchEditarParceiroRequest() {
  yield takeEvery(
    actions.EDITAR_PARCEIRO_REQUEST,
    function* ({ idParceiro, params, callback }: actionEditarParceiroRequest) {
      try {
        yield put(appActions.setLoading(true));

        yield call(api.patchEditarParceiro, idParceiro, params);

        yield put(actions.patchEditarParceiroSuccess(idParceiro));

        if (typeof callback === 'function') {
          callback();
        }

        yield put(appActions.setLoading(false));
      } catch (error) {
        console.error(error);
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* patchEditarParceiroSuccess() {
  yield takeEvery(
    actions.EDITAR_PARCEIRO_SUCCESS,
    function* ({ idParceiro }: actionEditarParceiroSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          title: 'Parceiro editado',
          message: 'Parceiro editado com sucesso',
        },
      });

      yield put({
        type: actions.FETCH_PARTNER_REQUEST,
        payload: idParceiro,
      });

      yield put({
        type: experienceActions.FETCH_HISTORIC_REQUEST,
        id: idParceiro,
        payload: { page: 1 },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchPartnersListRequest),
    spawn(fetchUsersRequest),
    spawn(postReenviarConviteRequest),
    spawn(fetchPartnerRequest),
    spawn(fetchChargesRequest),
    spawn(fetchPartnersClientsRequest),
    spawn(insertPartnerRequest),
    spawn(inactivateClientRequest),
    spawn(activatePartnerRequest),
    spawn(unblockPartnerRequest),
    spawn(blockPartnerRequest),
    spawn(linkClientsRequest),
    spawn(unlinkClientRequest),
    spawn(transferClientRequest),
    spawn(exemptChargeRequest),
    spawn(undoExemptChargeRequest),
    spawn(markAsPaydRequest),
    // spawn(patchPartnerRequest),
    spawn(changePartnerPlanRequest),
    spawn(separateChargeRequest),
    spawn(fetchDepositionsRequest),
    spawn(postDepositionRequest),
    spawn(updateDepositionRequest),
    spawn(deleteDepositionRequest),
    spawn(transformPartnerClientRequest),
    spawn(fetchObservationsRequest),
    spawn(postObservationRequest),
    spawn(updateObservationRequest),
    spawn(deleteObservationRequest),
    spawn(patchEditarParceiroRequest),
    fork(patchPeopleSuccess),
    fork(insertPartnerSuccess),
    fork(inactivateClientSuccess),
    fork(activatePartnerSuccess),
    fork(unblockPartnerSuccess),
    fork(blockPartnerSuccess),
    fork(linkClientsSuccess),
    fork(unlinkClientSuccess),
    fork(transferClientSuccess),
    fork(exemptChargeSuccess),
    fork(undoExemptChargeSuccess),
    fork(markAsPaydSuccess),
    fork(separateChargeSuccess),
    fork(postDepositionSuccess),
    fork(updateDepositionSuccess),
    fork(deleteDepositionSuccess),
    fork(transformPartnerClientSuccess),
    fork(postObservationSuccess),
    fork(updateObservationSuccess),
    fork(deleteObservationSuccess),
    fork(patchEditarParceiroSuccess),
  ]);
}
