import type {
  UsefullInfo,
  Periodicidade,
  ObjPlanos,
  Periodos,
} from './estruturaListaPlanos.types';

/**
 * @param periodos Recebe um objeto com as chaves com o nome das periodicidades
 * @returns Promise<<ObjPlanos>>
 */

export async function estruturaListaPlanos(periodos: Periodos) {
  const objPlanos: ObjPlanos = {};

  return await new Promise((resolve) => {
    let periodo: keyof Periodos;
    for (periodo in periodos) {
      for (const plano of periodos[periodo]) {
        if (!objPlanos[plano.codigo]) {
          objPlanos[plano.codigo] = _tratarInformacaoPlano(plano);
        }
        objPlanos[plano.codigo].periodos.push(_tratarPeriodicidade(plano));
      }
    }
    resolve(objPlanos);
  });
}

function _tratarInformacaoPlano({
  codigo,
  codigoPlanoValor,
  descricao,
  nome,
}: UsefullInfo): UsefullInfo & {
  periodos: Periodicidade[];
} {
  const periodos: Periodicidade[] = [];
  return { codigo, codigoPlanoValor, descricao, nome, periodos };
}

function _tratarPeriodicidade({
  valorPeriodico,
  codigoPlanoValor,
  periodicidade,
}: Periodicidade): Periodicidade {
  return { valorPeriodico, codigoPlanoValor, periodicidade };
}
