import { all, call, fork, put, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import actions from './actions';
import type { actionType } from './types';

export function* citiesRequest() {
  yield takeEvery(actions.GET_CITIES_REQUEST, function* ({ payload }: actionType) {
    try {
      const newCityArr = [];
      const response: any = yield call(api.getCities, payload);
      const result = response.data;
      if (result.length) {
        for (const { codigo, nome, uf } of result) {
          newCityArr.push({
            key: codigo,
            value: nome,
            label: `${nome} - ${uf}`,
            //uf: uf,
          });
        }
      }
      yield put({ type: actions.GET_CITIES_SUCCESS, payload: newCityArr });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* citiesSuccess() {
  yield takeEvery(actions.GET_CITIES_SUCCESS, function* () {
    yield;
  });
}

export default function* rootSaga() {
  yield all([spawn(citiesRequest), fork(citiesSuccess)]);
}
