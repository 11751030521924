import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../../helpers/api';
import appActions from '../../app/actions';
import type { GeneralSuccess } from '../../types';
import actions from './actions';
import type {
  actionDeleteResourceRequest,
  actionFetchResourcesListRequest,
  actionInactivateActivateResourceRequest,
  actionInsertResourceRequest,
  actionUpdateResourceRequest,
} from './types';

export const ResourcesList = (state: any) => state.Resources.resourcesList;

export function* fetchResourcesListRequest() {
  yield takeEvery(
    actions.FETCH_RESOURCES_LIST_REQUEST,
    function* ({ payload, setLoading }: actionFetchResourcesListRequest) {
      try {
        const resourcesList: any = yield select(ResourcesList);

        if (setLoading && typeof setLoading === 'function') {
          setLoading(true);
        } else {
          yield put({ type: appActions.IS_LOADING, payload: true });
        }
        const result: any = yield call(api.fetchResources, payload);
        if (setLoading && typeof setLoading === 'function') {
          setLoading(false);
        } else {
          yield put({ type: appActions.IS_LOADING, payload: false });
        }

        if (payload.page && payload.page == 1) {
          yield put({
            type: actions.FETCH_RESOURCES_LIST_SUCCESS,
            payload: result,
          });
        } else if (payload.all) {
          yield put({
            type: actions.FETCH_RESOURCES_LIST_SUCCESS,
            payload: {
              ...resourcesList,
              data: [...result.data],
            },
          });
        } else {
          yield put({
            type: actions.FETCH_RESOURCES_LIST_SUCCESS,
            payload: {
              ...resourcesList,
              data: [...resourcesList.data, ...result.data],
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertResourceRequest() {
  yield takeEvery(
    actions.INSERT_RESOURCE_REQUEST,
    function* ({ payload, callback, filters }: actionInsertResourceRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.insertResource, payload);
        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.RESOURCE_SUCCESS,
          payload: {
            title: 'Recurso criado',
            message: 'Recurso foi criado com sucesso',
            filters,
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* updateResourceRequest() {
  yield takeEvery(
    actions.UPDATE_RESOURCE_REQUEST,
    function* ({ id, payload, callback, filters }: actionUpdateResourceRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.updateResource, id, payload);
        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.RESOURCE_SUCCESS,
          payload: {
            title: 'Recurso editado',
            message: 'Recurso foi editado com sucesso',
            filters,
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* deleteResourceRequest() {
  yield takeEvery(
    actions.DELETE_RESOURCE_REQUEST,
    function* ({ id, callback, filters }: actionDeleteResourceRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deleteResource, id);
        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.RESOURCE_SUCCESS,
          payload: {
            title: 'Recurso excluído',
            message: 'Recurso foi excluído com sucesso',
            filters,
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* inactivateActivateResourceRequest() {
  yield takeEvery(
    actions.INACTIVATEACTIVATE_RESOURCE_REQUEST,
    function* ({
      id,
      status,
      callback,
      filters,
    }: actionInactivateActivateResourceRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.inactivateActivateResource, id, status);
        if (typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.RESOURCE_SUCCESS,
          payload: {
            title: 'Recurso alterado',
            message: 'Recurso foi alterado com sucesso',
            filters,
          },
        });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* resourceSuccess() {
  yield takeEvery(actions.RESOURCE_SUCCESS, function* ({ payload }: GeneralSuccess) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: payload.title,
        message: payload.message,
      },
    });
    yield put({
      type: actions.FETCH_RESOURCES_LIST_REQUEST,
      payload: { ...payload.filters, page: 1, infiniteScroll: true },
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(fetchResourcesListRequest),
    spawn(insertResourceRequest),
    spawn(updateResourceRequest),
    spawn(deleteResourceRequest),
    spawn(inactivateActivateResourceRequest),
    fork(resourceSuccess),
  ]);
}
