import actions from './actions';
import type { InitStateProps } from './types';

const initialState: InitStateProps = {
  groupsList: {
    data: [],
    meta: {},
  },
  group: {
    data: {},
    plans: {
      data: [],
      meta: {},
    },
  },
};

export default function groupsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_GROUPS_LIST_SUCCESS:
      return {
        ...state,
        groupsList: {
          data: action.payload ? action.payload.data : initialState.groupsList.data,
          meta: action.payload ? action.payload.meta : initialState.groupsList.meta,
        },
      };
    case actions.FETCH_GROUP_SUCCESS: {
      return { ...state, group: { ...state.group, data: action.payload.data } };
    }
    case actions.FETCH_GROUP_PLANS_LIST_SUCCESS: {
      return {
        ...state,
        group: { ...state.group, plans: action.payload },
      };
    }
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
