import actions from './actions';
import type { SetupStateProps } from './types';

const initialState: SetupStateProps = {
  setup: {},
};

export default function setupReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_SETUP_SUCCESS:
      return {
        ...state,
        setup: action.payload ? action.payload : initialState.setup,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
