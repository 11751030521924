import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import type { GeneralSuccess } from '../types';
import actions from './actions';
import type {
  actionDeleteEmailRequest,
  actionFetchEmailsRequest,
  actionForceEmailRequest,
  actionSendEmailRequest,
  EmailPayload,
} from './types';

const EmailsList = (state: any) => state.Emails.emails;

export function* fetchEmailsRequest() {
  yield takeEvery(
    actions.FETCH_EMAILS_REQUEST,
    function* ({ payload, setLoading }: actionFetchEmailsRequest) {
      try {
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const emailsList: any = yield select(EmailsList);
        const result: any = yield call(api.fetchEmails, payload);
        if ((result.meta && result.meta.current_page === 1) || !emailsList.data) {
          yield put({
            type: actions.FETCH_EMAILS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_EMAILS_SUCCESS,
            payload: {
              ...emailsList,
              data: [...emailsList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* sendEmailRequest() {
  yield takeEvery(
    actions.SEND_EMAIL_REQUEST,
    function* ({ payload, partnerId, callback }: actionSendEmailRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: EmailPayload = yield call(api.sendEmail, payload);
        if (!!payload.data_agendamento) {
          yield put({
            type: actions.EMAIL_SUCCESS,
            payload: {
              title: 'E-mail agendado',
              message: 'O e-mail foi agendado com sucesso',
              partnerId: partnerId,
            },
          });
        } else {
          yield put({
            type: actions.EMAIL_SUCCESS,
            payload: {
              title: 'E-mail enviado',
              message: 'O e-mail foi enviado com sucesso',
              partnerId: partnerId,
            },
          });
        }

        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* deleteEmailRequest() {
  yield takeEvery(
    actions.DELETE_EMAIL_REQUEST,
    function* ({ id, partnerId, callback }: actionDeleteEmailRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: EmailPayload = yield call(api.deleteEmail, id);
        yield put({
          type: actions.EMAIL_SUCCESS,
          payload: {
            title: 'E-mail removido',
            message: 'O e-mail foi removido com sucesso',
            partnerId: partnerId,
          },
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* forceEmailRequest() {
  yield takeEvery(
    actions.FORCE_EMAIL_REQUEST,
    function* ({ id, partnerId, callback }: actionForceEmailRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: EmailPayload = yield call(api.forceSendEmail, id);
        yield put({
          type: actions.EMAIL_SUCCESS,
          payload: {
            title: 'E-mail enviado',
            message: 'O e-mail foi enviado com sucesso',
            partnerId: partnerId,
          },
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* emailSuccess() {
  yield takeEvery(actions.EMAIL_SUCCESS, function* ({ payload }: GeneralSuccess) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: payload.title,
        message: payload.message,
      },
    });
    yield put({
      type: actions.FETCH_EMAILS_REQUEST,
      payload: { page: 1, empresa: payload.partnerId },
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(sendEmailRequest),
    spawn(deleteEmailRequest),
    spawn(fetchEmailsRequest),
    spawn(forceEmailRequest),
    fork(emailSuccess),
  ]);
}
