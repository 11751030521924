import actions from './actions';
import type { PartnerTypeStateProps } from './types';

const initialState: PartnerTypeStateProps = {
  partnersTypesList: {
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    },
    extra: {
      totalRegistros: 0,
    },
  },
};

export default function partnersTypesReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_PARTNERS_TYPES_LIST_SUCCESS:
      return {
        ...state,
        partnersTypesList: {
          data: action.payload
            ? action.payload.data
            : initialState.partnersTypesList.data,
          meta: action.payload
            ? action.payload.meta
            : initialState.partnersTypesList.meta,
          extra: action.payload
            ? action.payload.extra
            : initialState.partnersTypesList.extra,
        },
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
