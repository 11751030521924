import { all, call, fork, put, spawn, takeEvery } from 'redux-saga/effects';

import Api from '../../helpers/api';
import { setCookie, removeCookie } from '../../helpers/session';
import appActions from '../app/actions';
import actions from './actions';

import Router from 'next/router';
import api from '../../helpers/api';

export function* googleRedirectRequest() {
  yield takeEvery(
    actions.GOOGLE_REDIRECT_REQUEST,
    function* ({ payload }: any): any {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.googleRedirect, payload);
        yield put({ type: actions.GOOGLE_REDIRECT_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield Router.push('/signin');
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* googleRedirectSuccess() {
  yield takeEvery(actions.GOOGLE_REDIRECT_SUCCESS, function* ({ payload }: any) {
    try {
      yield setCookie('id_token', payload.token, true);
      yield put({ type: appActions.IS_LOADING, payload: false });

      window.opener?.location.replace('/visao-geral');
      window.close();
    } catch (error) {
      yield removeCookie('id_token');
      window.opener?.location.replace('/signin');
      window.close();
      yield put({ type: appActions.HANDLE_ERRORS, payload: error });
    }
  });
}

export function* logoutRequest() {
  yield takeEvery(actions.LOGOUT_REQUEST, function* () {
    try {
      yield put({ type: actions.LOGOUT_SUCCESS });
    } catch (error) {
      yield put({ type: appActions.HANDLE_ERRORS, error });
    }
  });
}

export function* logoutSuccess() {
  yield takeEvery(actions.LOGOUT_SUCCESS, function* () {
    removeCookie('id_token');
    Router.replace('/signin');
    yield;
  });
}

export function* fetchTokenRequest() {
  yield takeEvery(actions.FETCH_TOKEN_REQUEST, function* ({ payload }: any): any {
    try {
      if (payload) {
        const result: any = yield call(Api.googleGetData, payload);
        if (result && result.data) {
          yield put({
            type: actions.FETCH_TOKEN_SUCCESS,
            payload: { token: payload, profile: result.data },
          });
        } else {
          removeCookie('id_token');
          Router.push('/signin');
        }
      } else {
        Router.push('/signin');
      }
    } catch (error) {
      yield put({ type: actions.LOGOUT_SUCCESS });
    }
  });
}

export default function* rootSaga() {
  yield all([
    spawn(googleRedirectRequest),
    spawn(logoutRequest),
    spawn(fetchTokenRequest),
    fork(logoutSuccess),
    fork(googleRedirectSuccess),
  ]);
}
