import { defaultMeta } from '../../../constants/defaultMeta';
import actions from './actions';
import { UserExperienceStateProps } from './types';

const initialState: UserExperienceStateProps = {
  experience: null,
  historic: {
    data: null,
    meta: defaultMeta,
  },
};

export default function partnersReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        experience: action.payload ? action.payload : initialState.experience,
      };
    }
    case actions.FETCH_HISTORIC_SUCCESS: {
      return {
        ...state,
        historic: action.payload ? action.payload : initialState.historic,
      };
    }

    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
