import type { Options } from '../../types';
import type { NewsPayload, NewsUpdatePayload } from './types';

const actions = {
  FETCH_NEWS_REQUEST: 'FETCH_NEWS_REQUEST',
  FETCH_NEWS_SUCCESS: 'FETCH_NEWS_SUCCESS',
  INSERT_NEWS_REQUEST: 'INSERT_NEWS_REQUEST',
  INSERT_NEWS_SUCCESS: 'INSERT_NEWS_SUCCESS',
  UPDATE_NEWS_REQUEST: 'UPDATE_NEWS_REQUEST',
  UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
  DELETE_NEWS_REQUEST: 'DELETE_NEWS_REQUEST',
  DELETE_NEWS_SUCCESS: 'DELETE_NEWS_SUCCESS',

  fetchNews: (payload: Options, setLoading?: (loading: boolean) => void) => ({
    type: actions.FETCH_NEWS_REQUEST,
    payload,
    setLoading,
  }),
  insertNews: (payload: NewsPayload, callback?: () => void) => ({
    type: actions.INSERT_NEWS_REQUEST,
    payload,
    callback,
  }),
  updateNews: (id: number, payload: NewsUpdatePayload, callback?: () => void) => ({
    type: actions.UPDATE_NEWS_REQUEST,
    id,
    payload,
    callback,
  }),
  deleteNews: (id: number, callback?: () => void) => ({
    type: actions.DELETE_NEWS_REQUEST,
    id,
    callback,
  }),
};

export default actions;
