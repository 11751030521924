import type { Options } from '../../types';

const actions = {
  FETCH_USERSACCESS_REQUEST: 'FETCH_USERSACCESS_REQUEST',
  FETCH_USERSACCESS_SUCCESS: 'FETCH_USERSACCESS_SUCCESS',
  BLOCK_USERACCESS_REQUEST: 'BLOCK_USERACCESS_REQUEST',
  BLOCK_USERACCESS_SUCCESS: 'BLOCK_USERACCESS_SUCCESS',
  ALLOW_USERACCESS_REQUEST: 'ALLOW_USERACCESS_REQUEST',
  ALLOW_USERACCESS_SUCCESS: 'ALLOW_USERACCESS_SUCCESS',

  USERACCESS_SUCCESS: 'USERACCESS_SUCCESS',

  fetchUsersAccess: (payload: Options, setLoading?: (payload: any) => void) => ({
    type: actions.FETCH_USERSACCESS_REQUEST,
    payload,
    setLoading,
  }),
  blockUserAccess: (id: number, callback?: () => void) => ({
    type: actions.BLOCK_USERACCESS_REQUEST,
    id,
    callback,
  }),
  allowUserAccess: (id: number, callback?: () => void) => ({
    type: actions.ALLOW_USERACCESS_REQUEST,
    id,
    callback,
  }),
};

export default actions;
