import { defaultMeta } from 'constants/defaultMeta';
import actions from './actions';
import type { UsersStateProps } from './types';

const initialState: UsersStateProps = {
  partnersList: {
    data: [],
    extra: {},
    meta: defaultMeta,
  },
  usersList: {
    data: [],
    meta: defaultMeta,
  },
  partnersClientsList: {
    data: [],
    meta: defaultMeta,
    extra: {},
  },
  partner: null,
  chargesList: [],
  depositions: {
    data: [],
    meta: defaultMeta,
    extra: {
      totalRegistros: 0,
    },
  },
  observations: {
    data: [],
    meta: defaultMeta,
  },
  partnersFilter: {
    situacao: 3, // inicial: ativos e bloqueados
  },
};

export default function partnersReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_PARTNERS_LIST_SUCCESS:
      return {
        ...state,
        partnersList: action.payload,
      };

    case actions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.payload ? action.payload : initialState.usersList,
      };
    case actions.FETCH_PARTNERS_CLIENTS_SUCCESS:
      return {
        ...state,
        partnersClientsList: action.payload
          ? action.payload
          : initialState.partnersClientsList,
      };
    case actions.FETCH_PARTNER_SUCCESS: {
      return {
        ...state,
        partner: action.payload ? action.payload : initialState.partner,
      };
    }
    case actions.FETCH_CHARGES_SUCCESS: {
      return {
        ...state,
        chargesList: action.payload ? action.payload : initialState.chargesList,
      };
    }
    case actions.FETCH_DEPOSITIONS_SUCCESS: {
      return {
        ...state,
        depositions: action.payload,
      };
    }
    case actions.FETCH_OBSERVATIONS_SUCCESS: {
      return {
        ...state,
        observations: action.payload ? action.payload : initialState.observations,
      };
    }
    case actions.PARTNERS_FILTER: {
      return {
        ...state,
        partnersFilter: action.payload
          ? action.payload
          : initialState.partnersFilter,
      };
    }

    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
