import type { Options } from '../../types';

const actions = {
  FETCH_GROUPS_LIST_REQUEST: 'FETCH_GROUPS_LIST_REQUEST',
  FETCH_GROUPS_LIST_SUCCESS: 'FETCH_GROUPS_LIST_SUCCESS',
  FETCH_GROUP_PLANS_LIST_REQUEST: 'FETCH_GROUP_PLANS_LIST_REQUEST',
  FETCH_GROUP_PLANS_LIST_SUCCESS: 'FETCH_GROUP_PLANS_LIST_SUCCESS',
  FETCH_GROUP_REQUEST: 'FETCH_GROUP_REQUEST',
  FETCH_GROUP_SUCCESS: 'FETCH_GROUP_SUCCESS',
  INSERT_GROUP_REQUEST: 'INSERT_GROUP_REQUEST',
  INSERT_GROUP_SUCCESS: 'INSERT_GROUP_SUCCESS',
  UPDATE_GROUP_REQUEST: 'UPDATE_GROUP_REQUEST',
  UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
  DELETE_GROUP_REQUEST: 'DELETE_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS: 'DELETE_GROUP_SUCCESS',
  UNLINK_GROUP_PLAN_REQUEST: 'UNLINK_GROUP_PLAN_REQUEST',
  UNLINK_GROUP_PLAN_SUCCESS: 'UNLINK_GROUP_PLAN_SUCCESS',
  INACTIVATE_GROUP_REQUEST: 'INACTIVATE_GROUP_REQUEST',
  INACTIVATE_GROUP_SUCCESS: 'INACTIVATE_GROUP_SUCCESS',
  ACTIVATE_GROUP_REQUEST: 'ACTIVATE_GROUP_REQUEST',
  ACTIVATE_GROUP_SUCCESS: 'ACTIVATE_GROUP_SUCCESS',

  fetchGroupsList: (payload: Options, setLoading: (loading: boolean) => void) => ({
    type: actions.FETCH_GROUPS_LIST_REQUEST,
    payload,
    setLoading,
  }),
  fetchGroup: (id: number, callback?: () => void) => ({
    type: actions.FETCH_GROUP_REQUEST,
    payload: id,
    callback,
  }),
  fetchGroupPlansList: (id: number) => ({
    type: actions.FETCH_GROUP_PLANS_LIST_REQUEST,
    id,
  }),
  unlinkGroupPlan: (grupoId: number, planoId: number, callback?: () => void) => ({
    type: actions.UNLINK_GROUP_PLAN_REQUEST,
    payload: { primeiroid: grupoId, planoid: planoId },
    callback,
  }),
  insertGroup: (group: any, callback?: () => void) => ({
    type: actions.INSERT_GROUP_REQUEST,
    payload: group,
    callback,
  }),
  updateGroup: (id: number, group: any, callback?: () => void) => ({
    type: actions.UPDATE_GROUP_REQUEST,
    id: id,
    payload: group,
    callback,
  }),
  deleteGroup: (groupsIds: number | number[], callback?: () => void) => ({
    type: actions.DELETE_GROUP_REQUEST,
    payload: groupsIds,
    callback,
  }),
  inactivateGroup: (
    groupsIds: number | number[],
    inactivatePlans: 0 | 1,
    callback?: () => void
  ) => ({
    type: actions.INACTIVATE_GROUP_REQUEST,
    payload: groupsIds,
    inactivatePlans,
    callback,
  }),
  activateGroup: (groupsIds: number | number[], callback?: () => void) => ({
    type: actions.ACTIVATE_GROUP_REQUEST,
    payload: groupsIds,
    callback,
  }),
};

export default actions;
