import type { SetupPayload } from './types';

const actions = {
  FETCH_SETUP_REQUEST: 'FETCH_SETUP_REQUEST',
  FETCH_SETUP_SUCCESS: 'FETCH_SETUP_SUCCESS',
  UPDATE_SETUP_REQUEST: 'UPDATE_SETUP_REQUEST',
  UPDATE_SETUP_SUCCESS: 'UPDATE_SETUP_SUCCESS',

  fetchSetup: (callback?: (payload: any) => void) => ({
    type: actions.FETCH_SETUP_REQUEST,
    callback,
  }),
  updateSetup: (payload: SetupPayload, callback?: () => void) => ({
    type: actions.UPDATE_SETUP_REQUEST,
    payload,
    callback,
  }),
};

export default actions;
