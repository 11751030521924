import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { DashboardSlice } from './dashboard.types';

export const initialState: DashboardSlice = {
  data: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    storeDashboard: (state, { payload }: PayloadAction<DashboardSlice>) => {
      state.data = payload.data;
    },
  },
});

export default dashboardSlice;
