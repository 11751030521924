import { defaultMeta } from '../../../constants/defaultMeta';
import actions from './actions';
import type { NotificationsStateProps } from './types';

const initialState: NotificationsStateProps = {
  notifications: {
    data: [],
    meta: defaultMeta,
    extra: {
      ativas: 0,
      finalizadas: 0,
    },
  },
};

export default function notificationsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          data: action.payload
            ? action.payload.data
            : initialState.notifications.data,
          meta: action.payload
            ? action.payload.meta
            : initialState.notifications.meta,
          extra: action.payload
            ? action.payload.extra
            : initialState.notifications.extra,
        },
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
