import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultMeta } from '../../../constants/defaultMeta';

import type { IndicacoesSlice } from './indicacoesSlice.types';

export const initialState: IndicacoesSlice = {
  data: [],
  meta: defaultMeta,
  extra: {
    totalIndicacoes: 0,
  },
};

const indicacoesSlice = createSlice({
  name: 'indicacoesPerfil',
  initialState,
  reducers: {
    storeIndications: (state, { payload }: PayloadAction<IndicacoesSlice>) => {
      state.data = payload.data;
      state.meta = payload.meta;
      state.extra = payload.extra;
    },
  },
});

export default indicacoesSlice;
