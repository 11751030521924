import actions from './actions';
import type { GoalsStateProps } from './types';

const initialState: GoalsStateProps = {
  goals: null,
};

export default function goalsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_GOALS_SUCCESS:
      return {
        ...state,
        goals: action.payload ? action.payload : initialState.goals,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
