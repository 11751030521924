import type { Options, PartnerOptions } from '../types';
import type {
  actionEditarParceiroRequest,
  PartnerPayload,
  payloadReenviarConvite,
  TransformPayload,
} from './types';

const actions = {
  EDITAR_PARCEIRO_REQUEST: 'EDITAR_PARCEIRO_REQUEST',
  EDITAR_PARCEIRO_SUCCESS: 'EDITAR_PARCEIRO_SUCCESS',
  POST_REENVIAR_CONVITE_REQUEST: 'POST_REENVIAR_CONVITE_REQUEST',
  POST_REENVIAR_CONVITE_SUCCESS: 'POST_REENVIAR_CONVITE_SUCCESS',
  FETCH_PARTNERS_LIST_REQUEST: 'FETCH_PARTNERS_LIST_REQUEST',
  FETCH_PARTNERS_LIST_SUCCESS: 'FETCH_PARTNERS_LIST_SUCCESS',
  FETCH_PARTNER_REQUEST: 'FETCH_PARTNER_REQUEST',
  FETCH_PARTNER_SUCCESS: 'FETCH_PARTNER_SUCCESS',
  FETCH_CHARGES_REQUEST: 'FETCH_CHARGES_REQUEST',
  FETCH_CHARGES_SUCCESS: 'FETCH_CHARGES_SUCCESS',
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_PARTNERS_CLIENTS_REQUEST: 'FETCH_PARTNERS_CLIENTS_REQUEST',
  FETCH_PARTNERS_CLIENTS_SUCCESS: 'FETCH_PARTNERS_CLIENTS_SUCCESS',
  INSERT_PARTNER_REQUEST: 'INSERT_PARTNER_REQUEST',
  INSERT_PARTNER_SUCCESS: 'INSERT_PARTNER_SUCCESS',
  UPDATE_PARTNER_REQUEST: 'UPDATE_PARTNER_REQUEST',
  UPDATE_PARTNER_SUCCESS: 'UPDATE_PARTNER_SUCCESS',
  UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
  UPDATE_CLIENT_SUCCESS: 'UPDATE_CLIENT_SUCCESS',
  DELETE_PARTNER_REQUEST: 'DELETE_PARTNER_REQUEST',
  DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
  INACTIVATE_PARTNER_REQUEST: 'INACTIVATE_PARTNER_REQUEST',
  INACTIVATE_PARTNER_SUCCESS: 'INACTIVATE_PARTNER_SUCCESS',
  INACTIVATE_CLIENT_REQUEST: 'INACTIVATE_CLIENT_REQUEST',
  INACTIVATE_CLIENT_SUCCESS: 'INACTIVATE_CLIENT_SUCCESS',
  ACTIVATE_PARTNER_REQUEST: 'ACTIVATE_PARTNER_REQUEST',
  ACTIVATE_PARTNER_SUCCESS: 'ACTIVATE_PARTNER_SUCCESS',
  UNBLOCK_PARTNER_REQUEST: 'UNBLOCK_PARTNER_REQUEST',
  UNBLOCK_PARTNER_SUCCESS: 'UNBLOCK_PARTNER_SUCCESS',
  BLOCK_PARTNER_REQUEST: 'BLOCK_PARTNER_REQUEST',
  BLOCK_PARTNER_SUCCESS: 'BLOCK_PARTNER_SUCCESS',
  PATCH_PARTNER_REQUEST: 'PATCH_PARTNER_REQUEST',
  PATCH_PARTNER_SUCCESS: 'PATCH_PARTNER_SUCCESS',
  CHANGE_PLAN_REQUEST: 'CHANGE_PLAN_REQUEST',
  LINK_CLIENTS_REQUEST: 'LINK_CLIENTS_REQUEST',
  LINK_CLIENTS_SUCCESS: 'LINK_CLIENTS_SUCCESS',
  UNLINK_CLIENT_REQUEST: 'UNLINK_CLIENT_REQUEST',
  UNLINK_CLIENT_SUCCESS: 'UNLINK_CLIENT_SUCCESS',
  TRANSFER_CLIENT_REQUEST: 'TRANSFER_CLIENT_REQUEST',
  TRANSFER_CLIENT_SUCCESS: 'TRANSFER_CLIENT_SUCCESS',
  EXEMPT_CHARGE_REQUEST: 'EXEMPT_CHARGE_REQUEST',
  EXEMPT_CHARGE_SUCCESS: 'EXEMPT_CHARGE_SUCCESS',
  UNDOEXEMPT_CHARGE_REQUEST: 'UNDOEXEMPT_CHARGE_REQUEST',
  UNDOEXEMPT_CHARGE_SUCCESS: 'UNDOEXEMPT_CHARGE_SUCCESS',
  PAYD_CHARGE_REQUEST: 'PAYD_CHARGE_REQUEST',
  PAYD_CHARGE_SUCCESS: 'PAYD_CHARGE_SUCCESS',
  SEPARATE_CHARGE_REQUEST: 'SEPARATE_CHARGE_REQUEST',
  SEPARATE_CHARGE_SUCCESS: 'SEPARATE_CHARGE_SUCCESS',
  FETCH_DEPOSITIONS_REQUEST: 'FETCH_DEPOSITION_REQUEST',
  FETCH_DEPOSITIONS_SUCCESS: 'FETCH_DEPOSITION_SUCCESS',
  POST_DEPOSITION_REQUEST: 'POST_DEPOSITION_REQUEST',
  POST_DEPOSITION_SUCCESS: 'POST_DEPOSITION_SUCCESS',
  UPDATE_DEPOSITION_REQUEST: 'UPDATE_DEPOSITION_REQUEST',
  UPDATE_DEPOSITION_SUCCESS: 'UPDATE_DEPOSITION_SUCCESS',
  DELETE_DEPOSITION_REQUEST: 'DELETE_DEPOSITION_REQUEST',
  DELETE_DEPOSITION_SUCCESS: 'DELETE_DEPOSITION_SUCCESS',
  TRANSFORM_PARTNERCLIENT_REQUEST: 'TRANSFORM_PARTNERCLIENT_REQUEST',
  TRANSFORM_PARTNERCLIENT_SUCCESS: 'TRANSFORM_PARTNERCLIENT_SUCCESS',
  FETCH_OBSERVATIONS_REQUEST: 'FETCH_OBSERVATIONS_REQUEST',
  FETCH_OBSERVATIONS_SUCCESS: 'FETCH_OBSERVATIONS_SUCCESS',
  POST_OBSERVATION_REQUEST: 'POST_OBSERVATION_REQUEST',
  POST_OBSERVATION_SUCCESS: 'POST_OBSERVATION_SUCCESS',
  UPDATE_OBSERVATION_REQUEST: 'UPDATE_OBSERVATION_REQUEST',
  UPDATE_OBSERVATION_SUCCESS: 'UPDATE_OBSERVATION_SUCCESS',
  DELETE_OBSERVATION_REQUEST: 'DELETE_OBSERVATION_REQUEST',
  DELETE_OBSERVATION_SUCCESS: 'DELETE_OBSERVATION_SUCCESS',

  PARTNERS_FILTER: 'PARTNERS_FILTER',

  changePartnersFilter: (filter: Record<string, any>) => ({
    type: actions.PARTNERS_FILTER,
    payload: filter,
  }),

  fetchPartnersList: (
    payload: PartnerOptions,
    setLoading: (loading: boolean) => void
  ) => ({
    type: payload['vinculados-ao-parceiro']
      ? actions.FETCH_PARTNERS_CLIENTS_REQUEST
      : actions.FETCH_PARTNERS_LIST_REQUEST,
    payload,
    setLoading,
  }),
  fetchUsers: (
    id: number,
    payload: Options,
    setLoading: (loading: boolean) => void
  ) => ({
    type: actions.FETCH_USERS_REQUEST,
    id,
    payload,
    setLoading,
  }),
  fetchPartner: (id: number) => ({
    type: actions.FETCH_PARTNER_REQUEST,
    payload: id,
  }),
  fetchCharges: (id: number, payload?: any) => ({
    type: actions.FETCH_CHARGES_REQUEST,
    id,
    payload,
  }),
  insertPartner: (partner: any, callback?: (payload: any) => void) => ({
    type: actions.INSERT_PARTNER_REQUEST,
    payload: partner,
    callback,
  }),
  updatePartner: (id: number, partner: any, callback?: () => void) => ({
    type: actions.UPDATE_PARTNER_REQUEST,
    id: id,
    payload: partner,
    callback,
  }),
  updateClient: (id: number, client: any, callback?: () => void) => ({
    type: actions.UPDATE_CLIENT_REQUEST,
    id: id,
    payload: client,
    callback,
  }),
  deletePartner: (partnerIds: number | number[], callback?: () => void) => ({
    type: actions.DELETE_PARTNER_REQUEST,
    payload: partnerIds,
    callback,
  }),
  postReenviarConvite: (
    id: number,
    payload: payloadReenviarConvite,
    callback?: () => void
  ) => ({
    type: actions.POST_REENVIAR_CONVITE_REQUEST,
    id,
    payload,
    callback,
  }),
  inactivateClient: (
    payload: {
      codigo: number;
      codigoMoc: number;
      motivoInativacao: string;
      /** 0: Inativa apenas a empresa, 1: Inativa a empresa parceira e seus clientes*/
      tipoInativacao: 0 | 1;
    },
    partnerId: number | null,
    callback?: () => void,
    filters?: any
  ) => ({
    type: actions.INACTIVATE_CLIENT_REQUEST,
    payload,
    partnerId,
    callback,
    filters,
  }),
  activatePartner: (payload: any) => ({
    type: actions.ACTIVATE_PARTNER_REQUEST,
    payload: payload,
  }),
  unblockPartner: (
    payload: { codigo: number; tipoDesbloqueio: 0 | 1 },
    partnerId?: number,
    callback?: () => void
  ) => ({
    type: actions.UNBLOCK_PARTNER_REQUEST,
    payload,
    partnerId,
    callback,
  }),
  blockPartner: (
    payload: { codigo: number; motivoBloqueio?: string; tipoBloqueio: 0 | 1 },
    partnerId?: number,
    callback?: () => void
  ) => ({
    type: actions.BLOCK_PARTNER_REQUEST,
    payload,
    partnerId,
    callback,
  }),
  // patchPartner: (
  //   id: number,
  //   payload: PartnerPayload | any,
  //   callback?: () => void,
  //   cancelSuccess?: boolean
  // ) => ({
  //   type: actions.PATCH_PARTNER_REQUEST,
  //   id,
  //   payload,
  //   callback,
  //   cancelSuccess,
  // }),
  changePartnerPlan: (
    codigoClienteParceiro: number,
    payload: {
      codigoPlano: number;
      codigoPlanoValor: number;
      descricaoPlano?: string;
      diaVencimentoBoleto: number;
      diasCarencia: number;
      valorPlano: number;
    },
    callback?: () => void
  ) => ({
    type: actions.CHANGE_PLAN_REQUEST,
    codigoClienteParceiro,
    payload,
    callback,
  }),
  linkClients: (
    id: number,
    payload: { ids_empresas: number[] },
    callback?: () => void
  ) => ({
    type: actions.LINK_CLIENTS_REQUEST,
    id,
    payload,
    callback,
  }),
  unlinkClient: (id: number, partnerId: number, callback?: () => void) => ({
    type: actions.UNLINK_CLIENT_REQUEST,
    id,
    partnerId,
    callback,
  }),
  transferClient: (
    clientId: number,
    destinoId: number,
    id: number,
    callback?: () => void
  ) => ({
    type: actions.TRANSFER_CLIENT_REQUEST,
    clientId,
    destinoId,
    id,
    callback,
  }),
  exemptCharge: (values: any, callback?: () => void, partnerId?: number) => ({
    type: actions.EXEMPT_CHARGE_REQUEST,
    payload: values,
    callback,

    partnerId,
  }),
  undoExemptCharge: (values: any, callback?: () => void, partnerId?: number) => ({
    type: actions.UNDOEXEMPT_CHARGE_REQUEST,
    payload: values,
    callback,

    partnerId,
  }),
  markAsPayd: (values: any, callback?: () => void, partnerId?: number) => ({
    type: actions.PAYD_CHARGE_REQUEST,
    payload: values,
    callback,

    partnerId,
  }),
  separateCharge: (
    values: any,
    callback?: (payload: any) => void,
    partnerId?: number
  ) => ({
    type: actions.SEPARATE_CHARGE_REQUEST,
    payload: values,
    callback,
    partnerId,
  }),
  fetchDepositions: (payload: Options, callback?: (payload: any) => void) => ({
    type: actions.FETCH_DEPOSITIONS_REQUEST,
    payload,
    callback,
  }),
  postDeposition: (
    payload: {
      codigo_empresa: number;
      data: string;
      depoimento: string;
      nome: string;
    },
    partnerId?: number,
    callback?: (payload: any) => void
  ) => ({
    type: actions.POST_DEPOSITION_REQUEST,
    payload,
    partnerId,
    callback,
  }),
  updateDeposition: (
    id: number,
    payload: {
      codigo_empresa: number;
      data: string;
      depoimento: string;
      nome: string;
    },
    partnerId?: number,
    callback?: (payload: any) => void
  ) => ({
    type: actions.UPDATE_DEPOSITION_REQUEST,
    id,
    payload,
    partnerId,
    callback,
  }),
  deleteDeposition: (
    id: number,
    partnerId?: number,
    callback?: (payload: any) => void
  ) => ({
    type: actions.DELETE_DEPOSITION_REQUEST,
    id,
    partnerId,
    callback,
  }),
  transformPartnerClient: (
    id: number,
    payload: TransformPayload,
    callback?: (payload: any) => void
  ) => ({
    type: actions.TRANSFORM_PARTNERCLIENT_REQUEST,
    id,
    payload,
    callback,
  }),
  fetchObservations: (
    payload: Options & { empresa: number },
    setloading: (payload: any) => void
  ) => ({
    type: actions.FETCH_OBSERVATIONS_REQUEST,
    payload,
    setloading,
  }),
  postObservation: (
    payload: {
      descricao: string;
      empresa: number;
    },
    callback?: (payload: any) => void
  ) => ({
    type: actions.POST_OBSERVATION_REQUEST,
    payload,
    callback,
  }),
  updateObservation: (
    id: number,
    payload: {
      descricao: string;
      empresa: number;
    },
    callback?: (payload: any) => void
  ) => ({
    type: actions.UPDATE_OBSERVATION_REQUEST,
    id,
    payload,
    callback,
  }),
  deleteObservation: (
    id: number,
    partnerId: number,
    callback?: (payload: any) => void
  ) => ({
    type: actions.DELETE_OBSERVATION_REQUEST,
    id,
    partnerId,
    callback,
  }),
  patchEditarParceiro: (
    idParceiro: number,
    params: actionEditarParceiroRequest['params'],
    callback?: () => void
  ) => ({
    type: actions.EDITAR_PARCEIRO_REQUEST,
    idParceiro,
    params,
    callback,
  }),
  patchEditarParceiroSuccess: (idParceiro: number) => ({
    type: actions.EDITAR_PARCEIRO_SUCCESS,
    idParceiro,
  }),
};

export default actions;
