import { defaultMeta } from '../../constants/defaultMeta';
import actions from './actions';
import type { ResultsStateProps } from './types';

const initialState: ResultsStateProps = {
  results: null,
  upsell: {
    data: [],
    extra: {
      numeroUpgrade: 0,
      parceiros: 0,
      totalClientes: 0,
      totalUpgrade: 0,
    },
    meta: defaultMeta,
  },
  upsellRecursos: {
    data: [],
    extra: {
      contadorNumero: 0,
      contadorClientes: 0,
      contadorParceiros: 0,
      totalValor: 0,
    },
    meta: defaultMeta,
  },
  downsell: {
    data: [],
    extra: {
      numeroDowngrade: 0,
      parceiros: 0,
      totalClientes: 0,
      totalDowngrade: 0,
    },
    meta: defaultMeta,
  },
  downsellRecursos: {
    data: [],
    extra: {
      contadorNumero: 0,
      contadorClientes: 0,
      contadorParceiros: 0,
      totalValor: 0,
    },
    meta: defaultMeta,
  },
  income: {
    data: [],
    extra: {
      numeroClientesNovos: 0,
      parceiros: 0,
      totalClientes: 0,
      totalReceita: 0,
    },
    meta: defaultMeta,
  },
  churn: {
    data: [],
    extra: {
      numeroChurn: 0,
      parceiros: 0,
      totalChurn: 0,
      totalClientes: 0,
    },
    meta: defaultMeta,
  },
  exempt: {
    data: [],
    extra: {
      clientes: 0,
      parceiros: 0,
      qntdIsencoes: 0,
      totalIsencoes: 0,
    },
    meta: defaultMeta,
  },
  newClients: {
    data: [],
    extra: {
      clientes: 0,
      parceiros: 0,
      totalNovosClientes: 0,
      totalValor: 0,
    },
    meta: defaultMeta,
  },
  nps: {
    data: [],
    meta: defaultMeta,
    extra: {
      comentarios: 0,
      detratores: 0,
      indicacao: 0,
      naoVotantes: 0,
      neutros: 0,
      promotores: 0,
      receberamMasNaoClicaram: 0,
      votantes: 0,
    },
  },
  indications: {
    data: [],
    meta: defaultMeta,
  },
};

export default function resultsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.payload ? action.payload : initialState.results,
      };
    case actions.FETCH_UPSELL_SUCCESS:
      return {
        ...state,
        upsell: action.payload ? action.payload : initialState.upsell,
      };
    case actions.FETCH_UPSELL_RECURSOS_SUCCESS:
      return {
        ...state,
        upsellRecursos: action.payload
          ? action.payload
          : initialState.upsellRecursos,
      };
    case actions.FETCH_DOWNSELL_SUCCESS:
      return {
        ...state,
        downsell: action.payload ? action.payload : initialState.downsell,
      };
    case actions.FETCH_DOWNSELL_RECURSOS_SUCCESS:
      return {
        ...state,
        downsellRecursos: action.payload
          ? action.payload
          : initialState.downsellRecursos,
      };
    case actions.FETCH_INCOME_SUCCESS:
      return {
        ...state,
        income: action.payload ? action.payload : initialState.income,
      };
    case actions.FETCH_CHURN_SUCCESS:
      return {
        ...state,
        churn: action.payload ? action.payload : initialState.churn,
      };
    case actions.FETCH_EXEMPT_SUCCESS:
      return {
        ...state,
        exempt: action.payload ? action.payload : initialState.exempt,
      };
    case actions.FETCH_NEWCLIENTS_SUCCESS:
      return {
        ...state,
        newClients: action.payload ? action.payload : initialState.newClients,
      };
    case actions.FETCH_NPS_SUCCESS:
      return {
        ...state,
        nps: action.payload ? action.payload : initialState.nps,
      };
    case actions.FETCH_INDICATIONS_SUCCESS:
      return {
        ...state,
        indications: action.payload ? action.payload : initialState.indications,
      };

    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
