import { all, call, fork, put, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import resultActions from '../results/actions';
import actions from './actions';
import type {
  actionFetchGoalsRequest,
  actionPostGoalRequest,
  actionUpdateGoalRequest,
} from './types';

export function* fetchGoalsRequest() {
  yield takeEvery(
    actions.FETCH_GOALS_REQUEST,
    function* ({ payload, setLoading }: actionFetchGoalsRequest) {
      try {
        if (typeof setLoading === 'function') {
          setLoading({ type: 'get', value: true });
        }
        const result: any = yield call(api.fetchGoals, payload);
        if (typeof setLoading === 'function') {
          setLoading({ type: 'get', value: false });
        }
        yield put({
          type: actions.FETCH_GOALS_SUCCESS,
          payload: result.data,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertGoalsRequest() {
  yield takeEvery(
    actions.POST_GOALS_REQUEST,
    function* ({ payload, setLoading, callback }: actionPostGoalRequest) {
      try {
        if (typeof setLoading === 'function') {
          setLoading({ type: 'post', value: true });
        }
        const result: any = yield call(api.insertGoal, payload.data);
        if (result && typeof setLoading === 'function') {
          setLoading({ type: 'post', value: false });
        }
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({
          type: actions.POST_GOALS_SUCCESS,
          payload: payload.filters,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* updateGoalsRequest() {
  yield takeEvery(
    actions.UPDATE_GOALS_REQUEST,
    function* ({ id, payload, callback }: actionUpdateGoalRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.updateGoal, id, payload.data);
        yield put({
          type: actions.UPDATE_GOALS_SUCCESS,
          payload: payload.filters,
        });
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertGoalsSuccess() {
  yield takeEvery(actions.POST_GOALS_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Metas editadas',
        message: 'Metas editadas com sucesso.',
      },
    });
    yield put({
      type: actions.FETCH_GOALS_REQUEST,
      payload: payload.goals,
    });
    yield put({
      type: resultActions.FETCH_RESULTS_REQUEST,
      payload: payload.results,
    });
  });
}

export function* updateGoalsSuccess() {
  yield takeEvery(actions.UPDATE_GOALS_SUCCESS, function* ({ payload }: any) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Metas editadas',
        message: 'Metas editadas com sucesso.',
      },
    });
    yield put({
      type: actions.FETCH_GOALS_REQUEST,
      payload: payload.goals,
    });
    yield put({
      type: resultActions.FETCH_RESULTS_REQUEST,
      payload: payload.results,
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(fetchGoalsRequest),
    spawn(insertGoalsRequest),
    spawn(updateGoalsRequest),
    fork(insertGoalsSuccess),
    fork(updateGoalsSuccess),
  ]);
}
