import { all, call, fork, put, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../../helpers/api';
import appActions from '../../app/actions';
import actions from './actions';
import type {
  actionFetchSetupRequest,
  actionUpdateSetupRequest,
  Success,
} from './types';

export function* fetchSetupRequest() {
  yield takeEvery(
    actions.FETCH_SETUP_REQUEST,
    function* ({ callback }: actionFetchSetupRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.fetchSetup);
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: actions.FETCH_SETUP_SUCCESS, payload: result.data });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* updateSetupRequest() {
  yield takeEvery(
    actions.UPDATE_SETUP_REQUEST,
    function* ({ payload, callback }: actionUpdateSetupRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.updateSetup, payload);
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: actions.UPDATE_SETUP_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* updateSetupSuccess() {
  yield takeEvery(actions.UPDATE_SETUP_SUCCESS, function* ({ payload }: Success) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Setup inicial salvo',
        message: 'Setup inicial salvo com sucesso',
      },
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(fetchSetupRequest),
    spawn(updateSetupRequest),
    fork(updateSetupSuccess),
  ]);
}
