import { all } from 'redux-saga/effects';
import appSagas from './app/saga';
import authSagas from './auth/saga';
import citiesSagas from './cities/saga';
import emailsSagas from './emails/saga';
import gapsSagas from './gaps/saga';
import goalsSagas from './goals/saga';
import graphicsSagas from './graphics/saga';
import resultsSagas from './results/saga';
import groupsSagas from './settings/groups/saga';
import newsSagas from './settings/news/saga';
import notificationsSagas from './settings/notifications/saga';
import partnersTypesSagas from './settings/partnersTypes/saga';
import plansSagas from './settings/plans/saga';
import resourcesSagas from './settings/resources/saga';
import setupSagas from './settings/setupInicial/saga';
import usersAccessSagas from './settings/usersAccess/saga';
import userExperienceSagas from './users/experience/saga';
import partnersSagas from './users/saga';

export default function* rootSaga() {
  yield all([
    appSagas(),
    authSagas(),
    gapsSagas(),
    resultsSagas(),
    plansSagas(),
    resourcesSagas(),
    citiesSagas(),
    groupsSagas(),
    partnersTypesSagas(),
    partnersSagas(),
    userExperienceSagas(),
    emailsSagas(),
    goalsSagas(),
    newsSagas(),
    setupSagas(),
    notificationsSagas(),
    usersAccessSagas(),
    graphicsSagas(),
  ]);
}
