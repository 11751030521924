import type { Options } from '../../types';

const actions = {
  FETCH_RESOURCES_LIST_REQUEST: 'FETCH_RESOURCES_LIST_REQUEST',
  FETCH_RESOURCES_LIST_SUCCESS: 'FETCH_RESOURCES_LIST_SUCCESS',
  FETCH_RESOURCE_REQUEST: 'FETCH_RESOURCE_REQUEST',
  INSERT_RESOURCE_REQUEST: 'INSERT_RESOURCE_REQUEST',
  UPDATE_RESOURCE_REQUEST: 'UPDATE_RESOURCE_REQUEST',
  DELETE_RESOURCE_REQUEST: 'DELETE_RESOURCE_REQUEST',
  INACTIVATEACTIVATE_RESOURCE_REQUEST: 'INACTIVATEACTIVATE_RESOURCE_REQUEST',
  RESOURCE_SUCCESS: 'RESOURCE_SUCCESS',

  fetchResourcesList: (
    payload: Options,
    setLoading?: (loading: boolean) => void
  ) => ({
    type: actions.FETCH_RESOURCES_LIST_REQUEST,
    payload,
    setLoading,
  }),
  insertResource: (
    payload: {
      descricao?: string;
      descricaoImagem?: string;
      imagem?: string;
      nome?: string;
      slug?: string;
    },
    callback?: () => void,
    filters?: Options
  ) => ({
    type: actions.INSERT_RESOURCE_REQUEST,
    payload,
    callback,
    filters,
  }),
  updateResource: (
    id: number,
    payload: {
      descricao?: string;
      descricaoImagem?: string;
      imagem?: string;
      nome?: string;
      slug?: string;
    },
    callback?: () => void,
    filters?: Options
  ) => ({
    type: actions.UPDATE_RESOURCE_REQUEST,
    id,
    payload,
    callback,
    filters,
  }),
  deleteResource: (id: number, callback?: () => void, filters?: Options) => ({
    type: actions.DELETE_RESOURCE_REQUEST,
    id,
    callback,
    filters,
  }),
  inactivateActivateRequest: (
    id: number,
    status: 0 | 1,
    callback?: () => void,
    filters?: Options
  ) => ({
    type: actions.INACTIVATEACTIVATE_RESOURCE_REQUEST,
    id,
    status,
    callback,
    filters,
  }),
};

export default actions;
