import { all, takeEvery, put, spawn, select, call, fork } from 'redux-saga/effects';

import actions from './actions';
import appActions from '../../app/actions';

import api from '../../../helpers/api';

import {
  actionFetchNewsRequest,
  actionInsertNewsRequest,
  actionUpdateNewsRequest,
  actionDeleteNewsRequest,
  Success,
} from './types';

export const NewsList = (state: any) => state.News.news;

export function* fetchNewsRequest() {
  yield takeEvery(
    actions.FETCH_NEWS_REQUEST,
    function* ({ payload, setLoading }: actionFetchNewsRequest) {
      try {
        const newsList: any = yield select(NewsList);

        if (setLoading && typeof setLoading === 'function') {
          setLoading(true);
        } else {
          yield put({ type: appActions.IS_LOADING, payload: true });
        }
        const result: any = yield call(api.fetchNews, payload);
        if (setLoading && typeof setLoading === 'function') {
          setLoading(false);
        } else {
          yield put({ type: appActions.IS_LOADING, payload: false });
        }

        if ((result.meta && result.meta.current_page === 1) || !newsList.data) {
          yield put({
            type: actions.FETCH_NEWS_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_NEWS_SUCCESS,
            payload: {
              ...newsList,
              data: [...newsList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertNewsRequest() {
  yield takeEvery(
    actions.INSERT_NEWS_REQUEST,
    function* ({ payload, callback }: actionInsertNewsRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.insertNews, payload);
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: actions.INSERT_NEWS_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* insertNewsSuccess() {
  yield takeEvery(actions.INSERT_NEWS_SUCCESS, function* ({ payload }: Success) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Novidade criada',
        message: 'Novidade criada com sucesso',
      },
    });
    yield put({
      type: actions.FETCH_NEWS_REQUEST,
      payload: { page: 1 },
    });
  });
}

export function* updateNewsRequest() {
  yield takeEvery(
    actions.UPDATE_NEWS_REQUEST,
    function* ({ id, payload, callback }: actionUpdateNewsRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.updateNews, id, payload);
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: actions.UPDATE_NEWS_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* updateNewsSuccess() {
  yield takeEvery(actions.UPDATE_NEWS_SUCCESS, function* ({ payload }: Success) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Novidade editada',
        message: 'Novidade editada com sucesso',
      },
    });
    yield put({
      type: actions.FETCH_NEWS_REQUEST,
      payload: { page: 1 },
    });
  });
}

export function* deleteNewsRequest() {
  yield takeEvery(
    actions.DELETE_NEWS_REQUEST,
    function* ({ id, callback }: actionDeleteNewsRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        const result: any = yield call(api.deleteNews, id);
        if (result && typeof callback === 'function') {
          callback();
        }
        yield put({ type: actions.DELETE_NEWS_SUCCESS, payload: result });
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
        yield put({ type: appActions.IS_LOADING, payload: false });
      }
    }
  );
}

export function* deleteNewsSuccess() {
  yield takeEvery(actions.DELETE_NEWS_SUCCESS, function* ({ payload }: Success) {
    yield put({
      type: appActions.TOGGLE_SNACKBAR,
      payload: {
        activated: true,
        title: 'Novidade excluída',
        message: 'Novidade excluída com sucesso',
      },
    });
    yield put({
      type: actions.FETCH_NEWS_REQUEST,
      payload: { page: 1 },
    });
  });
}

export default function* rootSaga() {
  yield all([
    spawn(fetchNewsRequest),
    spawn(insertNewsRequest),
    spawn(updateNewsRequest),
    spawn(deleteNewsRequest),
    fork(insertNewsSuccess),
    fork(updateNewsSuccess),
    fork(deleteNewsSuccess),
  ]);
}
