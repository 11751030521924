import actions from './actions';
import type { CitiesInitialState } from './types';

const initialState: CitiesInitialState = {
  cities: [
    {
      key: 0,
      value: '',
      label: '',
      uf: '',
    },
  ],
};

export default function CitiesReducer(
  state: CitiesInitialState = initialState,
  action: any
) {
  switch (action.type) {
    case actions.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
