import { all, call, put, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../helpers/api';
import appActions from '../app/actions';
import actions from './actions';
import type { actionFetchGraphicsRequest } from './types';

export function* fetchGraphicsRequest() {
  yield takeEvery(
    actions.FETCH_GRAPHICS_REQUEST,
    function* ({ payload }: actionFetchGraphicsRequest) {
      try {
        const result: any = yield call(api.fetchGraphics, payload);
        yield put({
          type: actions.FETCH_GRAPHICS_SUCCESS,
          payload: result.data,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([spawn(fetchGraphicsRequest)]);
}
