import actions from './actions';
import type { ResourcesStateProps } from './types';

const initialState: ResourcesStateProps = {
  resourcesList: {
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0,
    },
    extra: {
      totalRecursos: 0,
    },
  },
};

export default function resourcesReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_RESOURCES_LIST_SUCCESS:
      return {
        ...state,
        resourcesList: action.payload,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
