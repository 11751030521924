import actions from './actions';
import type { InitStateProps } from './types';

const initState: InitStateProps = {
  cookieName: 'id_token',
  idToken: null,
  profile: {
    id: '',
    email: '',
    verified_email: false,
    given_name: '',
    family_name: '',
    picture: '',
    locale: '',
    hd: '',
  },
  sistema: {
    sis_codigo: 0,
    sis_name: '',
    id_hotjar: '',
  },
};

export default function authReducer(state = initState, action: any) {
  switch (action.type) {
    case actions.FETCH_TOKEN_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          idToken: action.payload.token ? action.payload.token : initState.idToken,
          profile: action.payload.profile
            ? action.payload.profile
            : initState.profile,
          sistema: action.payload.sistema
            ? action.payload.sistema
            : initState.sistema,
        };
      }
    case actions.GOOGLE_REDIRECT_SUCCESS: {
      if (action.payload) {
        return {
          ...state,
          idToken: action.payload.token ? action.payload.token : initState.idToken,
        };
      }
    }
    case 'LOGOUT_SUCCESS':
      return initState;
    default:
      return state;
  }
}
