import { defaultMeta } from '../../constants/defaultMeta';
import actions from './actions';
import type { EmailsStateProps } from './types';

const initialState: EmailsStateProps = {
  emails: {
    data: [],
    meta: defaultMeta,
  },
};

export default function emailsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_EMAILS_SUCCESS:
      return {
        ...state,
        emails: action.payload ? action.payload : initialState.emails,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
