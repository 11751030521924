import type { Options } from '../../types';

const actions = {
  FETCH_PARTNERS_TYPES_LIST_REQUEST: 'FETCH_PARTNERS_TYPES_LIST_REQUEST',
  FETCH_PARTNERS_TYPES_LIST_SUCCESS: 'FETCH_PARTNERS_TYPES_LIST_SUCCESS',
  FETCH_PARTNER_TYPE_REQUEST: 'FETCH_PARTNER_TYPE_REQUEST',
  INSERT_PARTNER_TYPE_REQUEST: 'INSERT_PARTNER_TYPE_REQUEST',
  UPDATE_PARTNER_TYPE_REQUEST: 'UPDATE_PARTNER_TYPE_REQUEST',
  DELETE_PARTNER_TYPE_REQUEST: 'DELETE_PARTNER_TYPE_REQUEST',
  INACTIVATE_PARTNER_TYPE_REQUEST: 'INACTIVATE_PARTNER_TYPE_REQUEST',
  ACTIVATE_PARTNER_TYPE_REQUEST: 'ACTIVATE_PARTNER_TYPE_REQUEST',
  TRANSFER_PARTNER_TYPE_REQUEST: 'TRANSFER_PARTNER_TYPE_REQUEST',

  PARTNER_TYPE_SUCCESS: 'PARTNER_TYPE_SUCCESS',

  fetchPartnersTypesList: (payload: Options & { status?: number }) => ({
    type: actions.FETCH_PARTNERS_TYPES_LIST_REQUEST,
    payload,
  }),
  fetchPartnerType: (id: number, callback?: () => void) => ({
    type: actions.FETCH_PARTNER_TYPE_REQUEST,
    payload: id,
    callback,
  }),
  insertPartnerType: (
    partnerType: any,
    callback?: () => void,
    filters?: Options
  ) => ({
    type: actions.INSERT_PARTNER_TYPE_REQUEST,
    payload: partnerType,
    callback,
    filters,
  }),
  updatePartnerType: (
    id: number,
    partnerType: any,
    callback?: () => void,
    filters?: Options
  ) => ({
    type: actions.UPDATE_PARTNER_TYPE_REQUEST,
    id: id,
    payload: partnerType,
    callback,
    filters,
  }),
  deletePartnerType: (
    partnerTypeIds: number | number[],
    callback?: () => void,
    filters?: Options
  ) => ({
    type: actions.DELETE_PARTNER_TYPE_REQUEST,
    payload: partnerTypeIds,
    callback,
    filters,
  }),
  inactivatePartnerType: (id: number, callback?: () => void, filters?: Options) => ({
    type: actions.INACTIVATE_PARTNER_TYPE_REQUEST,
    id,
    callback,
    filters,
  }),

  activatePartnerType: (id: number, callback?: () => void, filters?: Options) => ({
    type: actions.ACTIVATE_PARTNER_TYPE_REQUEST,
    id,
    callback,
    filters,
  }),
  transferPartnerType: (
    payload: {
      codigoTipoParceiro: number;
      empresas: number[];
    },
    callback: () => void,
    filters?: Options
  ) => ({
    type: actions.TRANSFER_PARTNER_TYPE_REQUEST,
    payload,
    callback,
    filters,
  }),
};

export default actions;
