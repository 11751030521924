import type { Options } from '../../types';

const actions = {
  FETCH_EXPERIENCE_REQUEST: 'FETCH_EXPERIENCE_REQUEST',
  FETCH_EXPERIENCE_SUCCESS: 'FETCH_EXPERIENCE_SUCCESS',
  FETCH_HISTORIC_REQUEST: 'FETCH_HISTORIC_REQUEST',
  FETCH_HISTORIC_SUCCESS: 'FETCH_HISTORIC_SUCCESS',

  fetchPartnerExperience: (id: number, payload: Options) => ({
    type: actions.FETCH_EXPERIENCE_REQUEST,
    id,
    payload,
  }),

  fetchPartnerHistoric: (id: number, payload: Options) => ({
    type: actions.FETCH_HISTORIC_REQUEST,
    id,
    payload,
  }),
};

export default actions;
