import actions from './actions';
import type { UsersAccessStateProps } from './types';

const initialState: UsersAccessStateProps = {
  usersAccess: {
    data: {
      usuarios_com_permissao: [],
      usuarios_sem_permissao: [],
    },
    extra: {
      usuarios_com_permissao: 0,
      usuarios_sem_permissao: 0,
    },
  },
};

export default function newsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actions.FETCH_USERSACCESS_SUCCESS:
      return {
        ...state,
        usersAccess: action.payload ? action.payload : initialState.usersAccess,
      };
    case 'LOGOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
}
