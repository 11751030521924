import { all, call, fork, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../../helpers/api';
import appActions from '../../app/actions';
import type { GeneralSuccess } from '../../types';
import actions from './actions';
import type {
  actionActivatePartnerTypeRequest,
  actionDeletePartnerTypeRequest,
  actionFetchPartnersTypesListRequest,
  actionInactivatePartnerTypeRequest,
  actionInsertPartnerTypeRequest,
  actionTransferPartnerTypeRequest,
  actionUpdatePartnerTypeRequest,
} from './types';

export const PartnersTypesList = (state: any) =>
  state.PartnersTypes.partnersTypesList;

export function* fetchPartnersTypesListRequest() {
  yield takeEvery(
    actions.FETCH_PARTNERS_TYPES_LIST_REQUEST,
    function* ({ payload }: actionFetchPartnersTypesListRequest) {
      try {
        const partnersTypesList: any = yield select(PartnersTypesList);

        yield put({ type: appActions.IS_LOADING, payload: true });
        const result: any = yield call(api.fetchPartnersTypes, payload);
        yield put({ type: appActions.IS_LOADING, payload: false });
        if (
          (result.meta && result.meta.current_page === 1) ||
          !partnersTypesList.data
        ) {
          yield put({
            type: actions.FETCH_PARTNERS_TYPES_LIST_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_PARTNERS_TYPES_LIST_SUCCESS,
            payload: {
              ...partnersTypesList,
              data: [...partnersTypesList.data, ...result.data],
              meta: result.meta,
              extra: result.extra,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* insertPartnerTypeRequest() {
  yield takeEvery(
    actions.INSERT_PARTNER_TYPE_REQUEST,
    function* ({ payload, callback, filters }: actionInsertPartnerTypeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.insertPartnerType, payload);
        yield put({
          type: actions.PARTNER_TYPE_SUCCESS,
          payload: {
            filters,
            title: 'Tipo de parceiro criado',
            message: 'O tipo de parceiro foi criado com sucesso.',
          },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* updatePartnerTypeRequest() {
  yield takeEvery(
    actions.UPDATE_PARTNER_TYPE_REQUEST,
    function* ({ id, payload, callback, filters }: actionUpdatePartnerTypeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.updatePartnerType, id, payload);
        yield put({
          type: actions.PARTNER_TYPE_SUCCESS,
          payload: {
            filters,
            title: 'Tipo de parceiro editado',
            message: 'O tipo de parceiro foi editado com sucesso.',
          },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* deletePartnerTypeRequest() {
  yield takeEvery(
    actions.DELETE_PARTNER_TYPE_REQUEST,
    function* ({ payload, callback, filters }: actionDeletePartnerTypeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });

        yield call(api.deletePartnerType, payload);
        yield put({
          type: actions.PARTNER_TYPE_SUCCESS,
          payload: {
            filters,
            title: 'Tipo de parceiro excluído',
            message: 'O tipo de parceiro foi excluído com sucesso.',
          },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* inactivatePartnerTypeRequest() {
  yield takeEvery(
    actions.INACTIVATE_PARTNER_TYPE_REQUEST,
    function* ({ id, callback, filters }: actionInactivatePartnerTypeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.inactivatePartnerType, id);
        yield put({
          type: actions.PARTNER_TYPE_SUCCESS,
          payload: {
            filters,
            title: 'Tipo de parceiro inativado',
            message: 'O tipo de parceiro foi inativado com sucesso.',
          },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* activatePartnerTypeRequest() {
  yield takeEvery(
    actions.ACTIVATE_PARTNER_TYPE_REQUEST,
    function* ({ id, callback, filters }: actionActivatePartnerTypeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.activatePartnerType, id);
        yield put({
          type: actions.PARTNER_TYPE_SUCCESS,
          payload: {
            filters,
            title: 'Tipo de parceiro ativado',
            message: 'O tipo de parceiro foi ativado com sucesso.',
          },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* transferPartnerTypeRequest() {
  yield takeEvery(
    actions.TRANSFER_PARTNER_TYPE_REQUEST,
    function* ({ payload, callback, filters }: actionTransferPartnerTypeRequest) {
      try {
        yield put({ type: appActions.IS_LOADING, payload: true });
        yield call(api.transferPartnerType, payload);
        yield put({
          type: actions.PARTNER_TYPE_SUCCESS,
          payload: {
            filters,
            title: 'Parceiros transferidos',
            message: 'Tipo de parceiro transferidos.',
          },
        });
        if (typeof callback === 'function') {
          callback();
        }
        yield put({ type: appActions.IS_LOADING, payload: false });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* partnerTypeSuccess() {
  yield takeEvery(
    actions.PARTNER_TYPE_SUCCESS,
    function* ({ payload }: GeneralSuccess) {
      yield put({
        type: appActions.TOGGLE_SNACKBAR,
        payload: {
          activated: true,
          title: payload.title,
          message: payload.message,
        },
      });
      yield put({
        type: actions.FETCH_PARTNERS_TYPES_LIST_REQUEST,
        payload: { ...payload.filters, page: 1, infiniteScroll: true },
      });
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchPartnersTypesListRequest),
    spawn(insertPartnerTypeRequest),
    spawn(updatePartnerTypeRequest),
    spawn(deletePartnerTypeRequest),
    spawn(inactivatePartnerTypeRequest),
    spawn(activatePartnerTypeRequest),
    spawn(transferPartnerTypeRequest),
    fork(partnerTypeSuccess),
  ]);
}
