import { Options, PlanosEGruposPayload, PlansGroupsOptions } from '../../types';

const actions = {
  FETCH_PLANOS_E_GRUPOS_REQUEST: 'FETCH_PLANOS_E_GRUPOS_REQUEST',
  FETCH_PLANOS_E_GRUPOS_SUCCESS: 'FETCH_PLANOS_E_GRUPOS_SUCCESS',
  PATCH_REMOVER_RECURSO_PLANO_REQUEST: 'PATCH_REMOVER_RECURSO_PLANO_REQUEST',
  PATCH_REMOVER_RECURSO_PLANO_SUCCESS: 'PATCH_REMOVER_RECURSO_PLANO_SUCCESS',
  PATCH_ADICIONAR_RECURSO_PLANO_REQUEST: 'PATCH_ADICIONAR_RECURSO_PLANO_REQUEST',
  PATCH_ADICIONAR_RECURSO_PLANO_SUCCESS: 'PATCH_ADICIONAR_RECURSO_PLANO_SUCCESS',

  FETCH_HISTORICO_PLANOS_REQUEST: 'FETCH_HISTORICO_PLANOS_REQUEST',
  FETCH_HISTORICO_PLANOS_SUCCESS: 'FETCH_HISTORICO_PLANOS_SUCCESS',
  FETCH_MEU_PLANO_REQUEST: 'FETCH_MEU_PLANO_REQUEST',
  FETCH_MEU_PLANO_SUCCESS: 'FETCH_MEU_PLANO_SUCCESS',
  FETCH_PLANSGROUPS_REQUEST: 'FETCH_PLANSGROUPS_REQUEST',
  FETCH_PLANSGROUPS_SUCCESS: 'FETCH_PLANSGROUPS_SUCCESS',
  FETCH_PLANS_LIST_REQUEST: 'FETCH_PLANS_LIST_REQUEST',
  FETCH_PLANS_LIST_SUCCESS: 'FETCH_PLANS_LIST_SUCCESS',
  FETCH_PLAN_REQUEST: 'FETCH_PLAN_REQUEST',
  FETCH_PLAN_SUCCESS: 'FETCH_PLAN_SUCCESS',
  INSERT_PLAN_REQUEST: 'INSERT_PLAN_REQUEST',
  UPDATE_PLAN_REQUEST: 'UPDATE_PLAN_REQUEST',
  DELETE_PLAN_REQUEST: 'DELETE_PLAN_REQUEST',
  SET_PLAN_SITUATION_REQUEST: 'SET_PLAN_SITUATION_REQUEST',
  PLAN_SUCCESS: 'PLAN_SUCCESS',

  fetchPlansGroupsList: (
    payload: PlansGroupsOptions,
    setLoading: (loading: boolean) => void
  ) => ({
    type: actions.FETCH_PLANSGROUPS_REQUEST,
    payload,
    setLoading,
  }),
  fetchHistoricoPlanos: (id: number, payload: Options) => ({
    type: actions.FETCH_HISTORICO_PLANOS_REQUEST,
    id,
    payload,
  }),
  fetchMeuPlanoRequest: (
    codigoClienteParceiro: number,
    setMostrar?: (mostrar: boolean) => void
  ) => ({
    type: actions.FETCH_MEU_PLANO_REQUEST,
    codigoClienteParceiro,
    setMostrar,
  }),
  fetchPlansList: (
    payload: Options & {
      /** 0: planos de cliente, 1: planos de parceiro */
      tipo?: 0 | 1;
    },
    setLoading: (loading: boolean) => void
  ) => ({
    type: actions.FETCH_PLANS_LIST_REQUEST,
    payload,
    setLoading,
  }),
  fetchPlan: (id: number, callback?: () => void) => ({
    type: actions.FETCH_PLAN_REQUEST,
    payload: id,
    callback,
  }),
  insertPlan: (plan: any, filters?: any, callback?: () => void) => ({
    type: actions.INSERT_PLAN_REQUEST,
    payload: plan,
    filters,
    callback,
  }),
  updatePlan: (id: number, plan: any, filters?: any, callback?: () => void) => ({
    type: actions.UPDATE_PLAN_REQUEST,
    id: id,
    payload: plan,
    filters,
    callback,
  }),
  deletePlan: (
    plansId: number | number[],
    filters?: any,
    callback?: () => void
  ) => ({
    type: actions.DELETE_PLAN_REQUEST,
    payload: plansId,
    filters,
    callback,
  }),
  setPlanSituation: (
    id: number,
    /** 1: ativar, 0: inativar */
    situation: 0 | 1,
    filters?: any,
    callback?: () => void
  ) => ({
    type: actions.SET_PLAN_SITUATION_REQUEST,
    id,
    situation,
    filters,
    callback,
  }),
  patchAdicionarRecursoPlanoRequest: (
    codigoClienteParceiro: number,
    idRecurso: number,
    valorRecurso: number
  ) => ({
    type: actions.PATCH_ADICIONAR_RECURSO_PLANO_REQUEST,
    codigoClienteParceiro,
    idRecurso,
    valorRecurso,
  }),
  patchAdicionarRecursoPlanoSuccess: (codigoClienteParceiro: number) => ({
    type: actions.PATCH_ADICIONAR_RECURSO_PLANO_SUCCESS,
    codigoClienteParceiro,
  }),
  patchRemoverRecursoPlanoRequest: (
    codigoClienteParceiro: number,
    idRecurso: number,
    imediatamente: 0 | 1
  ) => ({
    type: actions.PATCH_REMOVER_RECURSO_PLANO_REQUEST,
    codigoClienteParceiro,
    idRecurso,
    imediatamente,
  }),
  patchRemoverRecursoPlanoSuccess: (codigoClienteParceiro: number) => ({
    type: actions.PATCH_REMOVER_RECURSO_PLANO_SUCCESS,
    codigoClienteParceiro,
  }),
  fetchPlanosEGrupos: (payload: PlanosEGruposPayload) => ({
    type: actions.FETCH_PLANOS_E_GRUPOS_REQUEST,
    payload,
  }),
};

export default actions;
