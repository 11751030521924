import { all, call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import api from '../../../helpers/api';
import appActions from '../../app/actions';
import actions from './actions';
import type { actionFetchRequest } from './types';

export const HistoricList = (state: any) => state.UserExperience.historic;

export function* fetchPartnerExperienceRequest() {
  yield takeEvery(
    actions.FETCH_EXPERIENCE_REQUEST,
    function* ({ id, payload, setLoading }: actionFetchRequest) {
      try {
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchPartnerExperience, id, payload);
        if (typeof setLoading === 'function') {
          setLoading(false);
        }
        yield put({
          type: actions.FETCH_EXPERIENCE_SUCCESS,
          payload: result.data,
        });
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export function* fetchPartnerHistoricRequest() {
  yield takeEvery(
    actions.FETCH_HISTORIC_REQUEST,
    function* ({ id, payload, setLoading }: actionFetchRequest) {
      try {
        const historicList: any = yield select(HistoricList);
        if (typeof setLoading === 'function') {
          setLoading(true);
        }
        const result: any = yield call(api.fetchPartnerHistoric, id, payload);
        if (result && typeof setLoading === 'function') {
          setLoading(false);
        }
        if ((result.meta && result.meta.current_page === 1) || !historicList.data) {
          yield put({
            type: actions.FETCH_HISTORIC_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.FETCH_HISTORIC_SUCCESS,
            payload: {
              ...historicList,
              data: [...historicList.data, ...result.data],
              meta: result.meta,
            },
          });
        }
      } catch (error) {
        yield put({ type: appActions.HANDLE_ERRORS, payload: error });
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    spawn(fetchPartnerExperienceRequest),
    spawn(fetchPartnerHistoricRequest),
  ]);
}
